import React,{ useEffect, forwardRef, useRef } from 'react';
import { addDays, subDays } from 'date-fns';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import * as moment from 'moment';
import SelectCreatable from 'react-select/creatable';

export function randomArrayWithPercentage(arr, percent) {
    const percentage = percent / 100;
    const shuffledArray = [...arr];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [
            shuffledArray[j],
            shuffledArray[i],
        ];
    }
    const numToSelect = Math.floor(shuffledArray.length * percentage);
    const selectedElements = shuffledArray.slice(0, numToSelect);
    selectedElements.sort((a, b) => arr.indexOf(a) - arr.indexOf(b));
    return selectedElements;
}

export const successNotify = (message, time = 5000) => {
    toast.success(message, {
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    });
};

export const ErrorNotify = (message, time = 5000) => {
    toast.error(message, {
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    });
};

export const infoNotify = (message, time = 5000) => {
    toast.warning(message, {
        position: 'top-center',
        autoClose: time,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
    });
};

export function DateRangeFunction(startDate, endDate) {
    const today = new Date();
    return {
        startDate: new Date(
            new Date(subDays(today, endDate)).setHours('00', '00', '00')
        ),
        endDate: new Date(
            new Date(addDays(today, startDate)).setHours('23', '59', '59')
        ),
        key: 'selection',
    };
}

export function validateName(
    e,
    callback,
    characterLimit = false,
    setUseStateValue = false
) {
    const name = e.target.value;
    if (characterLimit) {
        if (name.length > characterLimit) {
            e.preventDefault();
            return;
        }
    }
    if (name.length == 1) {
        if (/^[A-Za-z0-9]+$/.test(name)) {
            callback(setUseStateValue ? e.target.value : e);
        } else {
            e.preventDefault();
        }
    } else if (name.length > 1) {
        const pattern = new RegExp('^[a-z0-9][a-z0-9\\. ]+$', 'gi');
        if (!pattern.test(name)) {
            e.preventDefault();
        } else {
            callback(setUseStateValue ? e.target.value : e);
        }
    } else {
        callback(setUseStateValue ? e.target.value : e);
    }
}

export function validateNameWithRegex(e, callback, options = {}) {
    const {
        regexAllowed = false,
        characterLimit = false,
        setUseStateValue = false,
        restrictedKey = [],
    } = options;
    const inputValue = e.target.value;
    if (restrictedKey.includes(e.key)) {
        e.preventDefault();
        return;
    } else if (e.key == 'Backspace' || e.key == 'Delete') {
        return;
    } else if (characterLimit && inputValue.length > characterLimit) {
        e.preventDefault();
        return;
    } else if (
        regexAllowed &&
        !/^[a-zA-Z0-9]+$/.test(inputValue) &&
        inputValue.length === 1
    ) {
        e.preventDefault();
        return;
    } else if (
        regexAllowed &&
        !new RegExp(`${regexAllowed}`, 'gi').test(inputValue) &&
        inputValue.length > 1
    ) {
        e.preventDefault();
        return;
    } else {
        callback(setUseStateValue ? e.target.value : e);
    }
}

export function lodashGet(obj, key) {
    const input = _.get(obj, key, '');
    if ((input == null || input == '') && input != 0) {
        return '';
    }
    const number = _.toNumber(input);
    if (_.isNumber(number) && !_.isNaN(number)) {
        return isFloat(number) ? number.toFixed(1) : number;
    }
    return input;
}

function isFloat(n) {
    return Number(n) === n && n % 1 !== 0;
}

export function lastSevenDaysQuery() {
    const { startDate, endDate } = DateRangeFunction(0, 6);
    return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        organisationId: '{{org-id}}',
    };
}

export function ImageComponent(src) {
    return (
        <img
            style={{ width: '100%', height: '100%' }}
            src={imagePath(src)}
            alt='image'
        />
    );
}

export const handleKeyPress = (e, additionalChars = []) => {
    const E_CHARACTER = 69;
    const MINUS_CHARACTER = 107;
    const MINUS_CHARACTER2 = 189;
    const PLUS = 109;
    const PLUS2 = 187;
    const DOT = 190;
    const keyCode = e.charCode || e.keyCode;
    if (
        [
            E_CHARACTER,
            MINUS_CHARACTER,
            MINUS_CHARACTER2,
            PLUS,
            PLUS2,
            ...additionalChars,
            DOT,
        ].includes(keyCode)
    ) {
        e.preventDefault();
        e.stopPropagation();
    }
};

export function getColumnsIndex(recInfo, colName) {
    let idx = -1;
    for (const i in recInfo) {
        if (recInfo[i].key == colName) {
            idx = i;
            break;
        }
    }
    return idx;
}

export function filterCallsByDuration(callsArray, callDuration) {
    const [durationStart, durationEnd] = callDuration.split('-');
    const [startHour, startMinute, startSecond] = durationStart
        .split(':')
        .map(Number);
    const [endHour, endMinute, endSecond] = durationEnd.split(':').map(Number);

    const durationInSecondsStart =
        startHour * 3600 + startMinute * 60 + startSecond;
    const durationInSecondsEnd = endHour * 3600 + endMinute * 60 + endSecond;

    const newarr = callsArray.filter(call => {
        if (
            !call.recordInfo[getColumnsIndex(call.recordInfo, 'Call Duration')]
                ?.value
        ) {
            return true;
        }

        const [callStartHour, callStartMinute, callStartSecond] =
            call.recordInfo[
                getColumnsIndex(call.recordInfo, 'Call Duration')
            ].value
                .split(':')
                .map(Number);
        const [callEndHour, callEndMinute, callEndSecond] =
            call.recordInfo[6].value.split(':').map(Number);

        const callStartTimeInSeconds =
            callStartHour * 3600 + callStartMinute * 60 + callStartSecond;
        const callEndTimeInSeconds =
            callEndHour * 3600 + callEndMinute * 60 + callEndSecond;

        return (
            callEndTimeInSeconds - callStartTimeInSeconds >=
            durationInSecondsStart &&
            callEndTimeInSeconds - callStartTimeInSeconds <=
            durationInSecondsEnd
        );
    });
    return newarr;
}

export function filterCallsByTimeRange(callsArray, timeRange) {
    const [rangeStart, rangeEnd] = timeRange.split('-');
    const [startHour, startMinute, startSecond] = rangeStart
        .split(':')
        .map(Number);
    const [endHour, endMinute, endSecond] = rangeEnd.split(':').map(Number);
    const rangeStartTimeInSeconds =
        startHour * 3600 + startMinute * 60 + startSecond;
    const rangeEndTimeInSeconds = endHour * 3600 + endMinute * 60 + endSecond;
    return callsArray.filter(call => {
        if (!call.recordInfo[5]?.value || !call.recordInfo[6]?.value) {
            return true;
        }
        const [callStartHour, callStartMinute, callStartSecond] =
            call.recordInfo[5].value.split(':').map(Number);
        const [callEndHour, callEndMinute, callEndSecond] =
            call.recordInfo[5].value.split(':').map(Number);

        const callStartTimeInSeconds =
            callStartHour * 3600 + callStartMinute * 60 + callStartSecond;
        const callEndTimeInSeconds =
            callEndHour * 3600 + callEndMinute * 60 + callEndSecond;
        return (
            (callStartTimeInSeconds >= rangeStartTimeInSeconds &&
                callStartTimeInSeconds <= rangeEndTimeInSeconds) ||
            (callEndTimeInSeconds >= rangeStartTimeInSeconds &&
                callEndTimeInSeconds <= rangeEndTimeInSeconds) ||
            (callStartTimeInSeconds <= rangeStartTimeInSeconds &&
                callEndTimeInSeconds >= rangeEndTimeInSeconds)
        );
    });
}

export function queryStringToObject(queryString) {
    const params = new URLSearchParams(queryString);
    const result = {};
    try {
        for (const [key, value] of params) {
            if (key === 'startDate' || key === 'endDate') {
                result[key] = new Date(decodeURIComponent(value));
            } else {
                result[key] = value;
            }
        }
        return result;
    } catch (error) {
        return result;
    }
}

export function throttleFunction(cb, delay = 1000) {
    let wait = false;
    return async (...args) => {
        if (wait) {
            return;
        }
        cb(...args);
        wait = true;
        setTimeout(() => {
            wait = false;
        }, delay);
    };
}

export async function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function sortByPos(list, returnAsObj = false) {
    list = JSON.parse(JSON.stringify(list));
    const obj = [];
    for (const key in list) {
        obj[list[key].pos] = list[key];
        obj[list[key].pos].key = key;
    }
    const finalArr = [];
    for (const key in obj) {
        if (obj[key]) {
            obj[key].pos = finalArr.length;
            finalArr.push(obj[key]);
        }
    }
    if (returnAsObj) {
        const finalObj = {};
        for (const row of finalArr) {
            finalObj[row.key] = row;
        }
        return finalObj;
    } else {
        return finalArr;
    }
}

export function formatUtcDateToTime(utcDateString) {
    if (!moment(utcDateString, moment.ISO_8601, true).isValid()) return '';
    const utcDate = moment(utcDateString);
    const formattedTime = utcDate.format('h:mm A').toLowerCase();
    return formattedTime;
}

export function timeDifferenceMinBool(refreshTime, timeInMinutes) {
    const currentTime = moment();
    const refreshMoment = moment(refreshTime);
    const differenceInMinutes = currentTime.diff(refreshMoment, 'minutes');
    return differenceInMinutes > timeInMinutes;
}

export function toolTipHOC(
    title,
    toolTipTitle = title,
    toolTipVisible = true,
    placement = 'left'
) {
    return toolTipVisible ? (
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip>{toolTipTitle}</Tooltip>}
        >
            <span>{title}</span>
        </OverlayTrigger>
    ) : (
        title
    );
}

export function orderObject(inputObject, orderArray, sortAsPerPos = false) {
    if (!inputObject) {
        return {};
    }
    if (!orderArray) {
        return inputObject;
    }
    const resultObject = {};
    orderArray.forEach((key, index) => {
        if (inputObject.hasOwnProperty(key)) {
            if (sortAsPerPos) {
                const sortedSubParameter = sortByPosToObject(
                    inputObject[key]?.[0]
                );
                resultObject[key] = [sortedSubParameter];
            } else {
                resultObject[key] = inputObject[key];
            }
        } else {
            const extraKeys = Object.keys(inputObject).filter(
                key => !orderArray.includes(key)
            )?.[0];
            resultObject[extraKeys] = inputObject[extraKeys];
        }
    });
    return resultObject;
}

function sortByPosToObject(object) {
    const entries = Object.entries(object);
    entries.sort(function (a, b) {
        return a[1].pos - b[1].pos;
    });
    const sortedObject = Object.fromEntries(
        entries.map(([key, value]) => [key, value])
    );
    return sortedObject;
}

export function imagePath(imagePath) {
    return process.env.PUBLIC_URL + imagePath;
}

export function urlPath(subUrl) {
    return process.env.PUBLIC_URL + subUrl;
}

export function loadingData(n) {
    return Array.from({ length: 10 }, (_, index) => index).map(temp => {
        return (
            <tr className='p_relative' key={'row-ld-' + temp}>
                {Array.from({ length: n }, (_, index) => index).map(temp2 => (
                    <td key={'row-ld-' + temp2}>
                        <div style={{ height: '10px' }} className='shimmer'>
                            &nbsp;
                        </div>
                    </td>
                ))}
            </tr>
        );
    });
}

const useSetElementDisplay = (elementId, displayStyle) => {
    useEffect(() => {
        const findElement = () => {
            return new Promise(resolve => {
                const start = Date.now();
                const maxWaitTime = 10000;

                const interval = setInterval(() => {
                    const scriptElement = document.querySelector(
                        `#${elementId}`
                    );
                    if (scriptElement || Date.now() - start >= maxWaitTime) {
                        clearInterval(interval);
                        resolve(scriptElement);
                    }
                }, 100); // Check every 100ms
            });
        };

        findElement().then(scriptElement => {
            if (scriptElement) {
                scriptElement.style.display = displayStyle;
            }
        });
    }, [elementId, displayStyle]);
};

export default useSetElementDisplay;

export function findUniqueArrayOfOptions(arr) {
    const uniqueData = arr.filter(
        (item, index, self) =>
            index === self.findIndex(t => t.value === item.value)
    );
    return uniqueData;
}

export const SelectWithCreatable = forwardRef(
    (
        {
            id,
            placeholder,
            onChange,
            value,
            maxLength,
            isNotValid,
            options,
            labelPrefix,
        },
        ref
    ) => {
        return (
            <SelectCreatable
                id={id}
                onKeyDown={e =>
                    validateNameWithRegex(e, e => e, {
                        characterLimit: maxLength,
                    })
                }
                value={
                    value?.length <= 250 && {
                        label: value,
                        value: value,
                    }
                }
                formatCreateLabel={e => {
                    return `Add new ${labelPrefix} '${e.trim()}'`;
                }}
                className={isNotValid ? 'invalid' : ''}
                options={options.filter(el => el.value !== value)}
                placeholder={placeholder}
                onChange={onChange}
                maxLength={maxLength}
            />
        );
    }
);

function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                onOutsideClick();
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref, onOutsideClick]);
}

export function OutsideClick({ children, onOutsideClick }) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, onOutsideClick);

    return <div ref={wrapperRef}>{children}</div>;
}
