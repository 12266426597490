import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import moment from 'moment';
import CustomLegend from './CustomLegend';

const LineGraph = ({ data, yaxis }) => {
    const hasData = data && data.length > 0;
    const placeholderData = [
        { auditDate: moment().format('YYYY-MM-DD') }
    ];

    const departments = (hasData ? data : placeholderData).flatMap(entry =>
        entry.departments?.map(d => d.department)
    );

    const generateLineColor = department => {
        const colors = {
            [departments[0]]: '#344054',
            [departments[1]]: 'rgb(29, 176, 86)',
        };

        return colors[department] || 'rgb(0, 0, 0)';
    };

    const allCounts = (hasData ? data : placeholderData).flatMap(entry =>
        entry.departments?.map(d => d.count)
    );

    const minY = 0;
    const maxY = Math.ceil(Math.max(...allCounts) + 10);
    const uniqueDepartments = Array.from(new Set(departments));

    return (
        <ResponsiveContainer width='100%' height={380}>
            <LineChart data={hasData ? data : placeholderData}>
                <XAxis
                    stroke='#475467'
                    dataKey='auditDate'
                    tickFormatter={tick => moment(tick).format('MMM DD')}
                    label={{
                        value: 'Time Period',
                        position: 'insideBottom',
                        offset: -5,
                        fill: "#475467",
                        style: {
                            textAnchor: 'middle',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                        },
                    }}
                    tick={{ fontFamily: 'Inter, sans-serif', fontSize: '10px' }}
                />
                <YAxis
                    stroke='#475467'
                    label={{
                        value: yaxis,
                        angle: -90,
                        position: 'insideLeft',
                        offset: 25,
                        fill: "#475467",
                        style: {
                            textAnchor: 'middle',
                            fontFamily: 'Inter, sans-serif',
                            fontSize: '14px',
                        },
                    }}
                    domain={[minY, maxY]}
                    tick={{ fontFamily: 'Inter, sans-serif', fontSize: '10px' }}
                />
                <Tooltip />
                <Legend
                    content={<CustomLegend />}
                    iconType='circle'
                    layout='horizontal'
                    verticalAlign='top'
                    align='left'
                    wrapperStyle={{
                        paddingBottom: 40,
                        fontSize: '14px',
                        fontWeight: '500',
                    }}
                />
                {uniqueDepartments?.map(department => (
                    <Line
                        key={department}
                        type='monotone'
                        dataKey={d =>
                            d.departments?.find(
                                dep => dep.department === department
                            )?.count || 0
                        }
                        name={department}
                        stroke={generateLineColor(department)}
                        strokeWidth={2}
                        dot={false}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
};

export default LineGraph;
