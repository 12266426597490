import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { imagePath } from '../../CommonLogic'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as AuthSelector from '../../store/auth/selectors';

const Settings = () => {
  const orgStrId = useSelector(AuthSelector.orgStrId);
  const permissions = useSelector(AuthSelector.accessPermissions);
  const userInfo = useSelector(AuthSelector.loggedInUser);
  const organization = useSelector(AuthSelector.organization);

  const hasPermission = (resource, type, subPermission = null) => {
    let toReturn = false;

    if (type == 'exists') {
      switch (resource) {
        case 'Agent':
          if (
            ['Agent', 'QA', 'Manager', 'Admin'].includes(
              userInfo.role
            )
          ) {
            return true;
          }
        case 'QA':
          if (['QA', 'Manager', 'Admin'].includes(userInfo.role)) {
            return true;
          }
        case 'Manager':
          if (['Manager', 'Admin'].includes(userInfo.role)) {
            return true;
          }
        case 'Admin':
          if (['Admin'].includes(userInfo.role)) {
            return true;
          }
      }
    } else {
      (permissions || []).map(permission => {
        if (permission.resource == resource && permission[type]) {
          toReturn = true;
        }
      });
    }

    return toReturn;
  };
  return (
    <>
      <div className="page-wrapper">
        <div className="content pb-0">
          <div className="row">
            <div className="col-sm-12 content_wrap_style">
              <div className="content_wrap_comman">
                <div className="">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="breadcrumb_box">
                        <Breadcrumb>
                          <Breadcrumb.Item as="li" href="/">
                            <img
                              src={
                                imagePath('/images/home_icon_pagig.svg')
                              }
                              alt=""
                            />
                          </Breadcrumb.Item>
                          <Breadcrumb.Item as="li" active aria-current="page">
                            Settings
                          </Breadcrumb.Item>
                        </Breadcrumb>
                      </div>
                    </div>
                  </div>
                  <div className="page_title_box">
                    <h3 className="page_title">Settings</h3>
                  </div>
                </div>
                <div className='row'>
                  {hasPermission('Organization Setup', 'create') ||
                    hasPermission('User Onboarding', 'create') ? (
                    <div className='col-lg-4 setting_card_box'>
                      <div className='setting_card card '>
                        <div className='setting_card_header d-flex justify-content-between align-items-center'>
                          <div className='setting_card_left d-flex align-items-center'>
                            <div className='setting_card_icon border'>
                              <img src={imagePath('/images/settings_manage_org.svg')} alt='' />
                            </div>
                            <div className='setting_card_name ms-3'>Manage Organisation</div>
                          </div>
                          <div className='setting_card_more'>
                            <Link to={`/o/${orgStrId}/setup/${permissions.some(
                              item => item.resource === 'Organization Setup'
                            )
                              ? 'org-setup'
                              : permissions.some(
                                item =>
                                  item.resource === 'User Onboarding'
                              )
                                ? 'user-management'
                                : ''
                              }`}><img src={imagePath('/images/arrow_right.svg')} alt='' /></Link>
                          </div>
                        </div>
                        <div className='setting_card_body'>
                          <p className='m-0'>Manage users across the oganisation</p>
                        </div>
                      </div>
                    </div>) : (
                    ''
                  )}
                  {hasPermission('Organisation Profile', 'create') ?
                    (<div className='col-lg-4 setting_card_box'>
                      <div className='setting_card card '>
                        <div className='setting_card_header d-flex justify-content-between align-items-center'>
                          <div className='setting_card_left d-flex align-items-center'>
                            <div className='setting_card_icon border'>
                              <img src={imagePath('/images/settings_org_profile.svg')} alt='' />
                            </div>
                            <div className='setting_card_name ms-3'>Organisation Profile</div>
                          </div>
                          <div className='setting_card_more'>
                            <Link to={`/o/${orgStrId}/setup/organisation-finance/all`}><img src={imagePath('/images/arrow_right.svg')} alt='' /></Link>
                          </div>
                        </div>
                        <div className='setting_card_body'>
                          <p className='m-0'>Update your organization details and security settings.</p>
                        </div>
                      </div>
                    </div>) : ("")}
                  {hasPermission('Billing', 'create') ?
                    (<div className='col-lg-4 setting_card_box'>
                      <div className='setting_card card '>
                        <div className='setting_card_header d-flex justify-content-between align-items-center'>
                          <div className='setting_card_left d-flex align-items-center'>
                            <div className='setting_card_icon border'>
                              <img src={imagePath('/images/settings_billing.svg')} alt='' />
                            </div>
                            <div className='setting_card_name ms-3'>Billing</div>
                          </div>
                          <div className='setting_card_more'>
                            <Link to={`/o/${orgStrId}/setup/manage-billing`} className=''><img src={imagePath('/images/arrow_right.svg')} alt='' /></Link>
                          </div>
                        </div>
                        <div className='setting_card_body'>
                          <p className='m-0'>Check billing cycle, monthly usage and invoices</p>
                        </div>
                      </div>
                    </div>) : ("")}
                  <div className='col-lg-4 setting_card_box'>
                    <div className='setting_card card '>
                      <div className='setting_card_header d-flex justify-content-between align-items-center'>
                        <div className='setting_card_left d-flex align-items-center'>
                          <div className='setting_card_icon border'>
                            <img src={imagePath('/images/settings_profile.svg')} alt='' />
                          </div>
                          <div className='setting_card_name ms-3'>My Profile</div>
                        </div>
                        <div className='setting_card_more'>
                          <Link to={`/o/${orgStrId}/profile-settings`} className=''><img src={imagePath('/images/arrow_right.svg')} alt='' /></Link>
                        </div>
                      </div>
                      <div className='setting_card_body'>
                        <p className='m-0'>Update your profile password, phone number and other details</p>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Settings