import React, { useEffect, useRef, useState } from 'react';
import { IoMdArrowDropright } from 'react-icons/io';
import TreeView, { flattenTree } from 'react-accessible-treeview';
import cx from 'classnames';
import './styles.css';
import { Modal } from 'react-bootstrap';
import { client } from '../../services/apiService';
import * as AuthSelector from '../../store/auth/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { validateToken } from '../../store/auth/reducers';
import { ErrorNotify, imagePath } from '../../CommonLogic';

function EditableTreeOrg({obsForOrganization,organigationName, selectedOrganization, getObs}) {
    const obsOrg = useSelector(AuthSelector.obs);
    const organization = useSelector(AuthSelector.organization);
    const [editNode, setEditNode] = useState(null);
    const [inputChangesMade, setInputChangesMade] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState({visible: false});
    const [userDetails, setUserDetails] = useState({});
    const organisationId = selectedOrganization?.value || "{{org-id}}";
    const [auditFormExist, setAuditFormExist] = useState(false);
    const loggedInUser = useSelector(AuthSelector.loggedInUser);
    const [data, setData] = useState({});
    const [items, setItems] = useState({ name: 'Initial', children: [] });
    const [itemsCopy, setItemsCopy] = useState({});
    const [editableText, setEditableText] = useState('');
    const [expandedIds,setExpandedIds] = useState([]);
    const stopDeletePropogation = useRef(false);
    const [enteredDataPosted, setEnteredDataPosted] = useState(false);

    useEffect(() => {
        setData(flattenTree(items, { preserveIds: true }));
    }, [items]);

    // Get Fusion organization and loggin user Details

    async function getUsersDetails () {
        const userD = await client.get(
            `/users?filters[email][$eq]=${loggedInUser?.email}&populate=Supervised_By&populate=role_map&populate=role_map.departments&populate=role_map.organistation_roles&populate=role_map.user_role`
        );
        const response = userD?.data?.[0];
        const obj = {
            id: response?.id,
            organistation_roles:
                response?.role_map?.[0]?.organistation_roles?.[0]?.id,
            user_role: response?.role_map?.[0]?.user_role?.id,
            departments: response?.role_map?.[0]?.departments,
        };
        setUserDetails(obj);
     };

    useEffect(() => {
        if (loggedInUser?.email) {
            getUsersDetails();
        }
    }, [loggedInUser?.email]);

    // formatting Department as per TreeView format
    function addLevel(arr, level = 1) {
        return arr.map(item => ({
            ...item,
            metadata:{level:level},
            children: item.children ? addLevel(item.children, level + 1) : []
        }));
    }

    useEffect(() => {
        if (obsForOrganization || obsOrg) {
            const formatted = extractOrganizationStructure(obsForOrganization || obsOrg);
            const data = formatAsPerTreeData(formatted);
            const dataWithLevel = addLevel(data?.children?.[0]?.children)
            const formattedDataWithMetaData = formatAsPerTreeData(dataWithLevel)
            setItems((formattedDataWithMetaData));
            const extractedData = {};
            getAllExistingDepartmentName(formatted, extractedData);
            setItemsCopy(extractedData);
        }
    }, [obsOrg, obsForOrganization]);

    // To handle Focus of input box
    useEffect(() => {
        setTimeout(()=>{
            const inputBox = document.getElementById('deptInput');
            if (inputBox) {
                inputBox.focus();
            }
        },[100])
        
    }, [editNode,items]);

    const formatAsPerTreeData = formatted => {
        return {
            name: '',
            children: [{ name: (organigationName || organization.organisationName), children: formatted, id: -1, isOpen: true, }],
        };
    };
    // Function to help formatting departments
    function extractOrganizationStructure(prop) {
        return prop.map(item => ({
            name: item.Department_Name,
            expanded: false,
            placeholder: 'Enter Department',
            edit: false,
            id: item.id,
            children:
                item.Departments.length > 0
                    ? extractOrganizationStructure(item.Departments)
                    : [],
        }));
    }

    // Function to get all department's name
    function getAllExistingDepartmentName(arr, obj) {
        for (const item of arr) {
            if (item.name) {
                obj[(item.name)?.toLowerCase()] = true;
            }
            if (item.children && item.children.length > 0) {
                getAllExistingDepartmentName(item.children, obj);
            }
        }
    }

    // User click on edit icon make field editable
    const handleEdit = async (e, node) => {
            e.stopPropagation()
        if (inputChangesMade || hasEmptyText(items?.children?.[0]?.children,"")) {
            if((node.id=="create")){
            }else{
                return
            }
        }
        setEditableText(node?.name);
        setEditNode(node);
    };

    const postDepartment = async payload => {
        const res = await client.post('/departments', { data: payload });
        return res;
    };

    const putDepartment = async (payload, id) => {
        payload.Organisation = {
            connect: [
                {
                    id: organisationId,
                },
            ],
        };
        payload.id = id;
        if (id) {
            const res = await client.put(`/departments/${id}`, {
                data: payload,
            });
            getObs(organisationId)
            return res;
        }
    };

    const updateUserDepartment = async data => {
        const res = await client.put(`/users/${userDetails?.id}`, data);
        return res;
    };

    const getOrganizationIds = async () => {
        const res = await client.get(
            `/organisations/getDepartmentIds/${organisationId}`
        );
        return res?.data?.[0]?.Departments?.map(item => item?.id);
    };

    const addRelationToDepartment = async (payload, id) => {
        const res = await client.put(`/departments/${id}`, { data: payload });
        return res;
    };

    const PostItemsData = async (arr, parentTexts, value) => {
        let newlyCreatedId = ""
        const payload = {
            Department_Name: value,
            Organisation: {
                connect: [
                    {
                        id: organisationId,
                    },
                ],
            },
        };

        if (parentTexts?.parent == -1) {
            try {
                if (parentTexts?.id == 'create') {
                    // Create Department
                    const res = await postDepartment(payload);
                    let parentDeptIds = [];
                    const deptId = res?.data?.data?.id
                    newlyCreatedId = deptId
                    parentDeptIds.push(deptId);
                    const orgDeptIds = await getOrganizationIds();
                    parentDeptIds = [...orgDeptIds,...parentDeptIds,];
                    //  Update Organization with new Department created
                    await client.put(`/organisations/${organisationId}`, {
                        data: {
                            Departments: [...new Set(parentDeptIds)],
                        },
                    });
                    // Update User with new Department
                    const currentDepartmentIds = (obsForOrganization || obsOrg)?.map(el => el.id) || [];
                    currentDepartmentIds?.push(deptId);
                    const data = {
                        role_map: [
                            {
                                departments: currentDepartmentIds,
                                organistation_roles: {
                                    id: userDetails?.organistation_roles,
                                },
                                user_role: userDetails?.user_role,
                            },
                        ],
                    };
                    await organization?.organisationName === organigationName && updateUserDepartment(data);
                    getObs(organisationId);
                }else{
                    await putDepartment(payload, parentTexts?.id);
                }
            } catch (e) {
                console.log(e);
            }
        } else {
            try{
                if (parentTexts?.id=="create") {
                    const res = await postDepartment(payload);
                    const deptId = res?.data?.data?.id;
                    newlyCreatedId = deptId;
                    const childIds = data?.filter((el)=>{
                        if(el.id==parentTexts?.parent){
                            return el
                        }
                    })
                    
                    const ids = childIds?.[0]?.children?.filter(item => item !== "create");
                    ids.push(deptId);
                     await addRelationToDepartment(
                        { Departments: ids },
                        parentTexts?.parent
                    );
                    getObs(organisationId)
                } else {
                    putDepartment(payload, parentTexts?.id);
                }
            }catch(e){

            }
        }
        return newlyCreatedId
    };

    const handleBlur = async (e, element, parent) => {
        const value = e.target.value;
        if(enteredDataPosted){
            return
        }
        if(!value){
            const inputBox = document.getElementById('deptInput');
            if (inputBox) {
                inputBox.focus();
            }
            return;
        }if(inputChangesMade && itemsCopy?.hasOwnProperty(value?.toLowerCase())) {
            ErrorNotify('The department already exists.');
            return;
        } else {
            delete itemsCopy[editableText];
            itemsCopy[value] = true;
        }

        setInputChangesMade(false);
 
        if (inputChangesMade) {
            if(!enteredDataPosted){
                await PostItemsData(items?.children?.[0]?.children, element, value);
                setEnteredDataPosted(true);
            }else{
                setEnteredDataPosted(false);
            }
        }
        const updatedItems = updateNode(items, element, value);
        setItems(updatedItems);
        setEditNode(null);
    };

    const updateNode = (tree, nodeToFind, newName) => {
        if (tree.id === nodeToFind.id) {
            return { ...tree, name: newName };
        }
        if (tree.children) {
            return {
                ...tree,
                children: tree.children.map(child =>
                    updateNode(child, nodeToFind, newName)
                ),
            };
        }
        return tree;
    };

    const handleChange = e => {
        e.preventDefault();
        setInputChangesMade(true);
        setEnteredDataPosted(false);
    };

    const handleKeyDown = (event,element) => {
        if (
          [
            'ArrowUp', 'ArrowDown', 'ArrowLeft','ArrowRight','-', '_',
            'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
            'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',
            'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
            'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',
          ].includes(event.key)
        ) {
          event.stopPropagation();
        }
            if (event.key === 'Enter') {
                handleBlur(event,element);
                setEnteredDataPosted(true);
            };
      };
      
    const itemKeyDown = e => {
        if (e.keyCode === 32) {
            e.stopPropagation();
        }
    };

    function hasEmptyText(arr, text) {
        for (let i = 0; i < arr?.length; i++) {
            const item = arr[i];
            if (item.name === "") {
                return true;
            }
            if (item.children && item.children.length > 0) {
                if (hasEmptyText(item.children, "")) {
                    return true;
                }
            }
        }
        return false;
    }
    
    const handlePlus = (e, parent,expanded) => {
        if(checkIdWithCreate(items)){
          return
        }
        setTimeout(()=>{
           const arr = [...expandedIds,parent?.id]
           const finalArr = (arr.filter((item, index) => arr.indexOf(item) === index))?.filter((el)=>el!="create");
            setExpandedIds(finalArr);
        },[50])
        
        if(expanded){
            e.stopPropagation()
        }
        if (inputChangesMade || hasEmptyText(items?.children?.[0]?.children,"")) {
            return
        }
        const newChild = {
            name: '',
            expanded: false,
            placeholder: 'Enter Department',
            edit: false,
            children: [],
            id: 'create',
        };
        const obj = {
            id: "create",
            name: "",
            children: [],
            parent: null,
            metadata:undefined,
        }
        setEditNode(obj);
        const updatedItems = updateNodeWithChild(items, parent, newChild);
        setItems(updatedItems);
    };

    const updateNodeWithChild = (tree, parentNode, childNode) => {
        if (tree.name === parentNode.name) {
            return { ...tree, children: [...tree.children, childNode] };
        }
        if (tree.children) {
            return {
                ...tree,
                children: tree.children.map(child =>
                    updateNodeWithChild(child, parentNode, childNode)
                ),
            };
        }
        return tree;
    };

     const handleModalVisibility = async (e,element,isExpanded) => {
        if (inputChangesMade || hasEmptyText(items?.children?.[0]?.children,"")) {
            return
        }
        const checkAuditForm = await client.get(`/audit-forms?populate[0]=Department&filters[Department]=${element?.id}`);
        if (checkAuditForm?.data?.length > 0) {
            setAuditFormExist(true);
        }
        setDeleteModalVisible({ visible: true, element })
    }

    function checkChildExist(arr, id) {
        function search(node) {
            if (node.id === id && node.children.length > 1) {
                return true;
            }
            for (const child of node.children) {
                if (search(child)) {
                    return true;
                }
            }
            return false;
        }
    
        return search(arr);
    }

    function checkIdWithCreate(arr) {
        function search(node) {
            if (node.id == "create") {
                return true;
            }
            for (const child of node.children) {
                if (search(child)) {
                    return true;
                }
            }
            return false;
        }
    
        return search(arr);
    }

    const handleDelete = async (element) => {
        stopDeletePropogation.current=false
        const newIds = removeElementFromArray(expandedIds,element?.id);
        let newIdsSS = newIds
        if(element?.parent!=-1 && !checkChildExist(items,element?.parent)){
            newIdsSS= removeElementFromArray(newIds,element?.parent);
        }
        
        const finalArr = newIdsSS.filter((item, index) => newIdsSS.indexOf(item) === index)?.filter((el)=>el!="create")
        setExpandedIds(finalArr);
        if(element?.id){
            const res = await client.put(`/departments/${element?.id}`, { data: { Is_Active: false } })
            if (res?.success) {
                setAuditFormExist(false);
                getObs(organisationId)
            }
        }
    };

    function removeElementFromArray(arr, element) {
        const index = arr.indexOf(element);
        if (index !== -1) {
            arr.splice(index, 1);
        }
        return arr;
    }

    return (
        <div>
            <div className='diy_treeview checkbox'>
                {data.length > 0 ? (
                   <TreeView
                       data={data}
                       aria-label='Checkbox tree'
                       multiSelect
                       propagateSelect
                       propagateSelectUpwards
                       togglableSelect
                       expandedIds={expandedIds}
                       onBlur={e => e}
                       nodeRenderer={({
                           element,
                           isBranch,
                           isExpanded,
                           isSelected,
                           isHalfSelected,
                           getNodeProps,
                           level,
                           handleSelect,
                           handleExpand,
                       }) => {
                           return (
                               <div
                               className='tree-node tree-node--expanded tree-node__branch'
                                   onKeyDown={itemKeyDown}
                                   {...getNodeProps({ onClick:(e)=>{
                                    if(isExpanded){
                                       const newIds = removeElementFromArray(expandedIds,element?.id);
                                       const finalArr = newIds.filter((item, index) => newIds.indexOf(item) === index)?.filter((el)=>el!="create")
                                      setExpandedIds(finalArr);
                                    }else{
                                        const arr = [...expandedIds,element?.id];
                                        const finalArr = arr.filter((item, index) => arr.indexOf(item) === index)?.filter((el)=>el!="create")
                                        setExpandedIds(finalArr);
                                    }
                                } })}
                                   style={{ marginLeft: 40 * (level - 1) }}>
                                   {isBranch && (
                                       <ArrowIcon isOpen={element?.id && isExpanded} />
                                   )}
                                   {editNode?.id === element?.id ? (
                                       <input
                                           type='text'
                                           className='form-control'
                                           defaultValue={element.name}
                                           onBlur={e =>
                                               handleBlur(e, element, parent)
                                           }
                                           onChange={handleChange}
                                           onKeyDown={(e)=>handleKeyDown(e,element)}
                                           id='deptInput'
                                           placeholder='Enter Department'
                                       />
                                   ) : (
                                       <span className='name diy_items_inner'>
                                          <span className='diy_Dept_Name'>{element.name || "Enter Department"}</span> 
                                           <div className=''>
                                               {/* Edit Icons */}
                                               {(element.id != -1 && element?.id!="create") && (
                                                   <span
                                                       className='pointer_cur'
                                                       onClick={e =>{
                                                           handleEdit(
                                                               e,
                                                               element
                                                           )
                                                       }}>
                                                       {loggedInUser.orgRole.startsWith('Sys.') && <img
                                                           alt=''
                                                           src={imagePath(
                                                               '/images/edit_pencil.svg'
                                                           )}
                                                       />}
                                                   </span>
                                               )}
                                               {/* Plus Icon */}
                                               {(element?.id!="create" && (element?.id!=-1 ? element?.metadata?.level<7 : true)) && <span className='pointer_cur'>
                                                   {loggedInUser?.orgRole?.startsWith('Sys.') && <img
                                                       style={{
                                                           width: '22px',
                                                       }}
                                                       onClick={e => {
                                                           handlePlus(
                                                               e,
                                                               element,
                                                               isExpanded
                                                           );
                                                       }}
                                                       src={imagePath(
                                                           '/images/add_icon_black.svg'
                                                       )}
                                                   />}
                                               </span>}
                                               {/* Delete */}
                                               {(element.children?.length===0 && element?.id!="create" && element?.id!="-1") && (
                                                   <span
                                                       className='ms-2 pointer_cur'
                                                       onClick={e => {
                                                        if(checkIdWithCreate(items)){
                                                            return
                                                          }
                                                           if (
                                                               (!inputChangesMade && element?.id)
                                                           ) {
                                                               handleModalVisibility(
                                                                   e,
                                                                   element,
                                                                   isExpanded
                                                               );
                                                           }
                                                       }}>
                                                       {loggedInUser?.orgRole?.startsWith('Sys.') && <img
                                                           src={imagePath(
                                                               '/images/delete_dark.svg'
                                                           )}
                                                       />}
                                                   </span>
                                               )}
                                           </div>
                                       </span>
                                   )}
                               </div>
                           );
                       }}
                   />
                
                ) : (
                    'Loading...'
                )}
            </div>
            {/* Delete confirmation popup */}
            <Modal
                show={deleteModalVisible.visible}
                backdrop='static'
                keyboard={false}
                className='alret_modal'>
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    <div className='alret_modal_body text-center'>
                        <h4 className='text-primary'>
                            {auditFormExist && "The department has an associated audit form."} Do you want to delete the department?
                        </h4>
                        <div className='btn_right mt-5'>
                            <button
                                type='button'
                                className='btn bordered_btn'
                                onClick={() =>
                                    setDeleteModalVisible({ ...deleteModalVisible, visible: false })
                                }>
                                No
                            </button>
                            <button
                                onClick={() => {
                                    if(deleteModalVisible?.element?.id){
                                        handleDelete(deleteModalVisible?.element)
                                        setDeleteModalVisible({ visible: false });
                                    }
                                }}
                                className='btn comman_btn ms-2'>
                                Yes
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
}

const ArrowIcon = ({ isOpen, className }) => {
    const baseClass = 'arrow';
    const classes = cx(
        baseClass,
        { [`${baseClass}--closed`]: !isOpen },
        { [`${baseClass}--open`]: isOpen },
        className
    );
    return <IoMdArrowDropright className={classes} />;
};

export default EditableTreeOrg;
