import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

const BarChart = ({data}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    // const data = [12, 19, 3, 5, 2, 3, 9, 14, 8, 15, 11, 10]; // Example data
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    const margin = { top: 20, right: 30, bottom: 30, left: 40 };
    const width = 560 - margin.left - margin.right;
    const height = 351 - margin.top - margin.bottom;

    const svg = d3.select(chartRef.current)
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    // X axis
    const x = d3.scaleBand()
      .domain(months)
      .range([0, width])
      .padding(0.2);

    svg.append('g')
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x));

    // Y axis
    const y = d3.scaleLinear()
      .domain([0, d3.max(data)])
      .nice()
      .range([height, 0]);

    svg.append('g')
      .call(d3.axisLeft(y));

    // Bars
    svg.selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', (d, i) => x(months[i]))
      .attr('y', d => y(d))
      .attr('width', 16)  // Bar width
      .attr('height', d => height - y(d))
      .attr('fill', '#126E36')
      .attr('rx', 8)
    //   .attr('ry', 8);

  }, []);

  return (
    <svg ref={chartRef}></svg>
  );
};

export default BarChart;
