(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define("ReactDropdownTreeSelect", ["react"], factory);
	else if(typeof exports === 'object')
		exports["ReactDropdownTreeSelect"] = factory(require("react"));
	else
		root["ReactDropdownTreeSelect"] = factory(root["React"]);
})(this, (__WEBPACK_EXTERNAL_MODULE__787__) => {
return 