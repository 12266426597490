/* eslint-disable react/display-name */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Form, Row, Col, Button } from 'react-bootstrap';
import ObsStructure from '../Audit/ObsStructure';
import Select from 'react-select';
import { delay, OutsideClick } from '../../CommonLogic';

function UserForm({
    dashboardRole,
    supervisor,
    designation = [],
    handleSubmit,
    handleCloseSingleUserModal,
    showUserEditModal,
    userDataForEdit={},
    obsForOrganization
}) {
    const userData = useRef(showUserEditModal?userDataForEdit:{});
    const {
        first_name = '',
        last_name = '',
        employee_id = '',
        email = '',
        Supervised_By = '',
        departments = '',
        organistation_roles = null,
        user_role = null,
        blocked=false,
        password=''
    } = userData.current;

    const [state,setState] =useState(false); //for rendering
    const obsOpenCloseRef= useRef(false)

    const handleChangeSingleUser = e => {
        const { name, value } = e.target;
        const temp = userData.current;
        temp[name] = value;
        if(['user_role','organistation_roles', 'Supervised_By'].includes(name)){
            setState(!state);
        }
        if (name == 'departments') {
            obsOpenCloseRef.current = true;
        }
        userData.current=temp;
    };

    const [validated, setValidated] = useState(false);
    
    const handleSubmitUser = e => {
        e.preventDefault();
        setValidated(true);
        const form = e.currentTarget.form;
        if (form.checkValidity() == false || (password && !/(?=.*[A-Z])(?=.*\d).{6,}/.test(password) || !organistation_roles || !user_role || !userData.current?.departments)) {
            e.preventDefault();
            e.stopPropagation();
            return true;
        }
        handleSubmit(userData.current);
    };

    const CustomFormControl = React.forwardRef(({ name, isNotValid }, ref) => {
        return (
            <ObsStructure
                selectedDepartment={{
                    name: '',
                    value: departments,
                }}
                setSelectedDepartment={value => {
                    handleChangeSingleUser({
                        target: {
                            name: 'departments',
                            value: value.value,
                        },
                    });
                }}
                multiselect={true}
                allView={true}
                selectPlaceholder={true}
                id={isNotValid ? 'obsParam' : ''}
                showPartiallySelected={false}
                obsForOrganization={obsForOrganization}
            />
        );
    });

    const CustomFormControlForEmployeeID = React.forwardRef(
        ({ defaultValue, isNotValid, id, name }, ref) => {
            const selectedEmployee = supervisor?.find(sup => sup.id === defaultValue);
            const selectedOption = selectedEmployee
                ? {
                      value: selectedEmployee.id,
                      label: `${selectedEmployee?.first_name+" "+selectedEmployee?.last_name} (${selectedEmployee.employee_id})`,
                  }
                : null;
            return (
                <Select
                    options={supervisor.map(sup => ({
                        value: sup.id,
                        label: `${sup?.first_name+" " + sup?.last_name} (${sup.employee_id})`,
                    }))}
                    defaultValue={selectedOption}
                    onChange={selectedOption => {
                        handleChangeSingleUser({
                            target: {
                                name,
                                value: selectedOption?.value,
                            },
                        });
                    }}
                    isSearchable
                    placeholder='Search Supervisor'
                    className={isNotValid ? 'invalid' : ''}
                    id={id}
                />
            );
        }
    );

    const CustomFormControlForDesignation = React.forwardRef(
        ({ defaultValue, isNotValid, id, name }, ref) => {
            const selectedRole = designation?.find(role => role.id === defaultValue);
            const selectedOption = selectedRole
                ? {
                      value: selectedRole.id,
                      label: selectedRole.Role_Name,
                  }
                : null;

            return (
                <Select
                    options={designation.map(des => ({
                        value: des.id,
                        label: des.Role_Name,
                    }))}
                    defaultValue={selectedOption}
                    onChange={selectedOption => {
                        handleChangeSingleUser({
                            target: {
                                name,
                                value: selectedOption?.value,
                            },
                        });
                    }}
                    isSearchable
                    placeholder='Search Designation'
                    className={isNotValid ? 'invalid' : ''}
                    id={id}
                />
            );
        }
    );

    const CustomFormControlForDashboardRole = React.forwardRef(
        ({ defaultValue, isNotValid, id, name }, ref) => {
            const selectedRole = dashboardRole?.find(role => role.id === defaultValue); 
            const selectedOption = selectedRole
                ? {
                      value: selectedRole.id,
                      label: selectedRole.attributes.role,
                  }
                : null;
            return (
                <Select
                    options={dashboardRole.map(role => ({
                        value: role.id,
                        label: role.attributes.role,
                    }))}
                    defaultValue={selectedOption}
                    onChange={selectedOption => {
                        handleChangeSingleUser({
                            target: {
                                name,
                                value: selectedOption?.value,
                            },
                        });
                    }}
                    isSearchable
                    placeholder='Search Dashboard Role'
                    className={isNotValid ? 'invalid' : ''}
                    id={id}
                />
            );
        }
    );

    const handleOutsideClick = async () => {
        if (obsOpenCloseRef.current == true) {
            obsOpenCloseRef.current = false;
            await delay(300)
            setState(!state)
        }
    };

    return (
        <Form validated={validated} className='form_style_comman'>
            <Row>
                <Col xs={6}>
                    <Form.Group className='form-group'>
                        <Form.Label>First Name <sup className='sup_manatory text-danger'>*</sup></Form.Label>
                        <Form.Control
                            type='text'
                            className='hg-prm-control'
                            placeholder='Enter first name'
                            defaultValue={first_name}
                            name='first_name'
                            onChange={handleChangeSingleUser}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            First Name is required
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={6}>
                    <Form.Group className='form-group'>
                        <Form.Label>Last Name <sup className='sup_manatory text-danger'>*</sup></Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Enter last name'
                            defaultValue={last_name}
                            name='last_name'
                            onChange={handleChangeSingleUser}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Last Name is required
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={6}>
                    <Form.Group className='form-group'>
                        <Form.Label>Employee ID <sup className='sup_manatory text-danger'>*</sup> </Form.Label>
                        <Form.Control
                            type='text'
                            placeholder='Enter employee ID'
                            defaultValue={employee_id}
                            name='employee_id'
                            onChange={handleChangeSingleUser}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Employee ID is required
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={6} className='diy_desig'>
                    <Form.Group className='form-group'>
                        <Form.Label>Supervised By</Form.Label>
                        <Form.Control
                            as={CustomFormControlForEmployeeID}
                            id='searchParam'
                            defaultValue={Supervised_By}
                            // isNotValid={Supervised_By ? false : true}
                            name='Supervised_By'
                            // required
                        />
                        <Form.Control.Feedback type='invalid' id='searchError'>
                            Supervised By is required
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={6}>
                    <Form.Group className='form-group'>
                        <Form.Label>Designation  <sup className='sup_manatory text-danger'>*</sup></Form.Label>
                        <Form.Control
                            as={CustomFormControlForDesignation}
                            id='searchParam'
                            defaultValue={organistation_roles}
                            isNotValid={organistation_roles ? false : true}
                            name='organistation_roles'
                            required
                        />
                        <Form.Control.Feedback type='invalid' id='searchError'>
                            Organistation Roles is required
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={6}>
                    <Form.Group className='form-group'>
                        <Form.Label>OBS <sup className='sup_manatory text-danger'>*</sup></Form.Label>
                        <OutsideClick onOutsideClick={handleOutsideClick}>
                            <Form.Control
                                as={CustomFormControl}
                                placeholder='Enter OBS'
                                isNotValid={departments ? false : true}
                                name='departments'
                            />
                        </OutsideClick>
                        <Form.Control.Feedback type='invalid' id={departments ? "" : "obsError"}>
                            OBS is required
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={6}>
                    <Form.Group className='form-group'>
                        <Form.Label>Email <sup className='sup_manatory text-danger'>*</sup></Form.Label>
                        <Form.Control
                            type='email'
                            placeholder='Enter email'
                            defaultValue={email}
                            name='email'
                            onChange={handleChangeSingleUser}
                            required
                        />
                        <Form.Control.Feedback type='invalid'>
                            Email is required
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                <Col xs={6}>
                    <Form.Group className='form-group'>
                        <Form.Label>Role  <sup className='sup_manatory text-danger'>*</sup></Form.Label>
                        <Form.Control
                            as={CustomFormControlForDashboardRole}
                            id='searchParam'
                            defaultValue={user_role}
                            isNotValid={user_role ? false : true}
                            name='user_role'
                            required
                        />
                        <Form.Control.Feedback type='invalid' id='searchError'>
                            Dashboard Role is required
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>

                {showUserEditModal && (
                  <Col xs={6}>
                  <Form.Group className='form-group'>
                      <Form.Label style={{display:"block"}}>User Status</Form.Label>
                      <select
                      style={{
                        width: "100%",
                        borderRadius: "5px",
                        borderColor: "#dcd2d2",
                        height: "39px",
                        paddingLeft: "15px",
                      }}
                          defaultValue={blocked}
                          name='blocked'
                          onChange={handleChangeSingleUser}
                          className='hg-prm-control'
                          required
                      >
                          <option value={false}>Active</option>
                          <option value={true}>Inactive</option>
                      </select>
                  </Form.Group>
              </Col>
            )}
                {showUserEditModal && (
                  <Col xs={6}>
                  <Form.Group className='form-group'>
                      <Form.Label>One Time Password </Form.Label>
                      <Form.Control
                          type='text'
                          className='hg-prm-control'
                          placeholder='Enter Password'
                          defaultValue={password}
                          isInvalid={validated ? password && !/(?=.*[A-Z])(?=.*\d).{6,}/.test(password):false }
                          name='password'
                          id='searchParam'
                          onChange={handleChangeSingleUser}
                      />
                      <Form.Control.Feedback type='invalid' id='searchError'>
                      Passwords must be at least 6 characters long and contain 1 capital letter and 1 digit.
                        </Form.Control.Feedback>
                  </Form.Group>
              </Col>
            )}

                <Col xs={12} className='text-end mt-3'>
                    <Button
                        
                        className='btn bordered_btn me-3'
                        onClick={()=>{
                          handleCloseSingleUserModal()
                          }}>
                        Cancel
                    </Button>
                    <Button
                        variant='primary'
                        type='button'
                        onClick={(e)=>{
                            setState((state)=>!state);
                            handleSubmitUser(e);
                        }}
                        >
                        
                        Submit
                    </Button>
                </Col>
            </Row>
        </Form>
    );
}

export default UserForm;
