const CustomLegend = ({ payload }) => (
    <div>
        <div className="custom_legend_wrap " >
            {payload.map((entry, index) => (
                <div className="custom_legend"
                    key={`item-${index}`}
                    style={{ display: "flex", alignItems: "center", marginBottom: 5 }}
                >
                    <div
                        style={{
                            width: 12,
                            height: 12,
                            borderRadius: "50%",
                            backgroundColor: entry.color,
                            marginRight: 5,

                        }}
                    />
                    <span style={{ color: "#344054" }}>{entry.value}</span>
                </div>
            ))}
        </div>
    </div>
);
export default CustomLegend;