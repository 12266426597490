import React, { useState } from 'react'
import { Breadcrumb, Button, Dropdown, Modal, Nav, OverlayTrigger, ProgressBar, Tab, Tooltip } from 'react-bootstrap'
import { imagePath } from '../../CommonLogic'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as AuthSelector from '../../store/auth/selectors';
import Card from '../bootstrap/card'
import { useSearchParams, useNavigate } from 'react-router-dom';
import DateRangePickerComp from '../../views/modules/social/AuditSheet/DateRangePickerComp'
// Flatpickr
import Flatpickr from 'react-flatpickr';
import BarChart from './BarChart'

const Billing = () => {
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const organization = useSelector(AuthSelector.organization);
    const navigate = useNavigate();

    // add designation model
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);

    const tracked_users = [
0,0,0,0,0,0,0,0,0,140,0,0
      ];

      const audit_calls = [0,0,0,0,0,0,0,0,0,40,0,0];

    // add designation model
    const [show3, setShow3] = useState(false);
    const [billingData, setBillingData] = useState(
        {
            billing: {
              billing_start: new Date(2024, 10, 1),
              billing_end: new Date(2024, 10, 30),
              status: 'active',
              next_payment: new Date(2024, 11, 1),
            },
            billing_plan: {
              type: 'mins_per_month',
              plan_time: 'month',
              plan_unit: 'mins',
              plan_value: 850000,
              plan_amount: 80000,
              plan_currency: '$',
              users_count: 200,
              deepgram_limit: 10000,
              gpt_limit: 15000,
              call_count: 300
            },
            consumption: {
              tracked_users: 150,
              deepgram_consumption: 1500,
              gpt_consumption: 300,
              total_audit_calls: 40,
            },
          }
      )
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);

    const getBillingAmountLabel = () => {
        return `${billingData?.billing_plan?.plan_amount} ${billingData?.billing_plan?.plan_currency}`
    }

    const getNextPaymentLabel = () => {
        const options = { month: 'short', day: 'numeric' };
        const nextPayment = new Date(billingData?.billing?.next_payment);
      
        return nextPayment.toLocaleDateString('en-US', options);
    }

    const getBillingCycleLabel = () => {
        console.log("here")
        const options = { month: 'short', day: 'numeric' };

        const billingStart = new Date(billingData?.billing?.billing_start);
        const billingEnd = new Date(billingData?.billing?.billing_end);
      
        const formattedStart = billingStart.toLocaleDateString('en-US', options);
        const formattedEnd = billingEnd.toLocaleDateString('en-US', options);
      
        return `${formattedStart} - ${formattedEnd}`;
    }

    const  getBillingPlanLabel = () => {
        console.log("here")
        const billingPlan = billingData?.billing_plan;
        console.log("here", billingPlan)
        if (!billingPlan) return '';
      
        const planValueLabel = billingPlan.plan_value >= 10000
          ? `${(billingPlan.plan_value / 1000).toFixed(0)}K`
          : billingPlan.plan_value;
      
        const planLabel = `${planValueLabel} ${billingPlan.plan_unit.replace('_', ' ')} per ${billingPlan.plan_time}`;
        return planLabel;
      };

      const getProgress = (count, limit) => {
        if (!count || !limit || limit === 0) return '0%';
        const percentage = ((count / limit) * 100).toFixed(2);
        return `${percentage}%`;
      };
      

    return (
        <>
            <div className="page-wrapper">
                <div className="content pb-0">
                    <div className="row">
                        <div className="col-sm-12 content_wrap_style">
                            <div className="content_wrap_comman">
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="breadcrumb_box">
                                                <Breadcrumb>
                                                    <Breadcrumb.Item as="li" href="/">
                                                        <img
                                                            src={
                                                                imagePath('/images/home_icon_pagig.svg')
                                                            }
                                                            alt=""
                                                        />
                                                    </Breadcrumb.Item>
                                                    <Breadcrumb.Item as="li" active aria-current="page">
                                                        Manage Billing
                                                    </Breadcrumb.Item>
                                                </Breadcrumb>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="page_title_box two_column_title">
                                        <h3 className="page_title">
                                            <span className='pe-3'><button className='btn btn_transparent'>
                                                <img onClick={() => { navigate(`/o/${orgStrId}/setup/manage-billing`) }} src={imagePath('/images/back_arrow.svg')} alt='back button' />
                                            </button></span> Billing
                                        </h3>
                                        <button
                                            className='btn bordered_btn'
                                            onClick={() => {
                                                handleShow3();
                                            }}
                                        >
                                            Edit Plan
                                        </button>
                                    </div>
                                </div>
                                {/* top card */}
                                <div className='row'>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card w-100'>
                                            <div className='two_column_title mb-3'>
                                                <h6 className='mb-0'>Billing</h6>
                                            </div>

                                            <h4 className='mb-0'>{getBillingPlanLabel()}</h4>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card w-100'>
                                            <div className='two_column_title mb-3'>
                                                <h6 className='mb-0'>Billing Cycle</h6>
                                            </div>

                                            <h4 className='mb-0'>{getBillingCycleLabel()}</h4>
                                        </div>
                                    </div>
                                    <div className='col-lg-4 card_legends_box'>
                                        <div className='card_legends card w-100'>
                                            <div className='two_column_title mb-3'>
                                                <h6 className='mb-0'>Next Payment</h6>
                                                <p className='mb-0'>{getNextPaymentLabel()} </p>
                                            </div>

                                            <h4 className='mb-0'>{getBillingAmountLabel()}</h4>
                                        </div>
                                    </div>
                                </div>

                                {/* tab */}
                                <div className='audit_list_view'>
                                    <div className='tab_view'>
                                        <Tab.Container
                                            defaultActiveKey='first'
                                        >
                                            <Nav
                                                variant='pills'
                                                data-toggle='slider-tab'
                                                role='tablist'>
                                                <Nav.Item role='presentation'>
                                                    <Nav.Link
                                                        eventKey='first'
                                                        variant='  d-flex align-items-center'
                                                        data-bs-toggle='tab'
                                                        data-bs-target='#pills-list_audit'
                                                        type='button'
                                                        role='tab'
                                                        aria-controls='home'
                                                        aria-selected='true'>
                                                        Invoice

                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item role='presentation'>
                                                    <Nav.Link
                                                        eventKey='second'
                                                        variant=' d-flex align-items-center'
                                                        data-bs-toggle='tab'
                                                        data-bs-target='#pills-rebuttal'
                                                        type='button'
                                                        role='tab'
                                                        aria-controls='profile'
                                                        aria-selected='false'>
                                                        Plan & Usage
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                            <Tab.Content className='mt-4'>
                                                <Tab.Pane
                                                    eventKey='first'
                                                    variant=' fade show active'
                                                    id='pills-list_audit'
                                                    role='tabpanel'
                                                    aria-labelledby='pills-list_audit-tab1'>
                                                    <div className=''>
                                                        <div>
                                                            <div className='two_column_title'>
                                                                <h3 className="page_title_sub_head">Invoice</h3>
                                                                <button
                                                                    className='btn bordered_btn'
                                                                    onClick={() => {
                                                                        handleShow3();
                                                                    }}
                                                                >
                                                                    Generate Invoice
                                                                </button>
                                                            </div>
                                                            <p className='subtitle_color mt-1 mb-0'>Check payment status and download invoices for past months</p>
                                                        </div>
                                                        <div className='table_style_comman mt-4'>
                                                            <div className='table-responsive'>
                                                                <table className="table">
                                                                    <thead>
                                                                        <tr>
                                                                            <th># Invoice No</th>
                                                                            <th>Date</th>
                                                                            <th>Status</th>
                                                                            <th>Amount</th>
                                                                            <th>Plan</th>
                                                                            <th></th>
                                                                            <th>Action</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td><span className='me-2'><img src={imagePath('/images/file_icon.svg')} alt='file icon' /></span>INV0001-Oct 24</td>
                                                                            <td>Oct 01, 2024</td>
                                                                            <td><span className='badge rounded-pill d-flex-inline align-items-center bg-soft-success'>
                                                                                <span className='me-1'><img src={imagePath('/images/tick_green.svg')} alt='' /></span> Paid
                                                                            </span></td>
                                                                            <td>$80000</td>
                                                                            <td>850k mins/month</td>
                                                                            <td><Link to="" className='link_text fw_600'>Download</Link></td>
                                                                            <td>
                                                                                <div>
                                                                                    <Link className='me-2'>
                                                                                        <img src={imagePath('/images/edit_pencil.svg')} alt='' />
                                                                                    </Link>
                                                                                    <Link>
                                                                                        <img src={imagePath('/images/mail_icon.svg')} alt='' style={{ width: '20px' }} />
                                                                                    </Link>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td><span className='me-2'><img src={imagePath('/images/file_icon.svg')} alt='file icon' /></span>INV0001-Jul 24</td>
                                                                            <td>Jul 01, 2024</td>
                                                                            <td><span className='badge rounded-pill d-flex-inline align-items-center bg-soft-danger'>
                                                                                <span className='me-1'><img src={imagePath('/images/error_icon.svg')} alt='' /></span>
                                                                                Pending
                                                                            </span></td>
                                                                            <td>$1000</td>
                                                                            <td>850k mins/month</td>
                                                                            <td><Link to="" className='link_text fw_600'>Download</Link></td>
                                                                            <td>
                                                                                <div>
                                                                                    <Link className='me-2'>
                                                                                        <img src={imagePath('/images/edit_pencil.svg')} alt='' />
                                                                                    </Link>
                                                                                    <Link>
                                                                                        <img src={imagePath('/images/mail_icon.svg')} alt='' style={{ width: '20px' }} />
                                                                                    </Link>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </Tab.Pane>

                                                <Tab.Pane
                                                    eventKey='second'
                                                    className='fade'
                                                    id='pills-rebuttal'
                                                    role='tabpanel'
                                                    aria-labelledby='pills-rebuttal-tab1'>
                                                    <div className=''>
                                                        {/* plan tracker */}
                                                        <div className=''>
                                                            <div>
                                                                <div className='two_column_title'>
                                                                    <h3 className="page_title_sub_head">Current Plan</h3>
                                                                    {/* <button
                                                                        className='btn bordered_btn'
                                                                        onClick={() => {
                                                                            handleShow4();
                                                                        }}
                                                                    >
                                                                        Reset Limit
                                                                    </button> */}
                                                                </div>
                                                                <p className='subtitle_color mt-1 mb-0'>Manage monthly feature usage and resources utilised under your current plan.</p>
                                                            </div>
                                                            <div className='plan_tracker card p-4 mt-4'>
                                                                <div className='row'>
                                                                    <div className='col-lg-6 mb-5 pb-2'>
                                                                        <div className="wrapper-progress">
                                                                            <div className="percentage-progress ">
                                                                                <h6 className='mb-2'>Monthly Tracked Users
                                                                                    <OverlayTrigger
                                                                                        placement='top'
                                                                                        overlay={<Tooltip>Active users for the account out of the total users on the platform</Tooltip>}
                                                                                    >
                                                                                        <span className='ms-2'><img src={imagePath('/images/info_icon.svg')} alt='' /></span>
                                                                                    </OverlayTrigger>
                                                                                </h6>
                                                                                <div className='progress_nu d-flex justify-content-between'>
                                                                                    <div>{billingData?.consumption?.tracked_users}</div>
                                                                                    <div>{billingData?.billing_plan?.users_count - billingData?.consumption?.tracked_users} Inactive</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="progress" style={{ height: "5px" }}>
                                                                            <ProgressBar className="mb-3" now={100} variant="success" style={{ width: getProgress(billingData?.consumption?.tracked_users, billingData?.billing_plan?.users_count), height: "5px" }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-6 mb-5 pb-2'>
                                                                        <div className="wrapper-progress">
                                                                            <div className="percentage-progress ">
                                                                                <h6 className='mb-2'>Total Audit Calls
                                                                                    <OverlayTrigger
                                                                                        placement='top'
                                                                                        overlay={<Tooltip>Number of calls audited out of total calls on the platform.</Tooltip>}
                                                                                    >
                                                                                        <span className='ms-2'><img src={imagePath('/images/info_icon.svg')} alt='' /></span>
                                                                                    </OverlayTrigger>
                                                                                </h6>
                                                                                <div className='progress_nu d-flex justify-content-between'>
                                                                                    <div>{billingData?.consumption?.total_audit_calls}</div>
                                                                                    <div>{billingData?.billing_plan?.call_count - billingData?.consumption?.total_audit_calls} Left</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="progress" style={{ height: "5px" }}>
                                                                            <ProgressBar className="mb-3" now={100} variant="success" style={{ width: getProgress(billingData?.consumption?.total_audit_calls, billingData?.billing_plan?.call_count), height: "5px" }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <div className="wrapper-progress">
                                                                            <div className="percentage-progress ">
                                                                                <h6 className='mb-2'>Deepgram Consumption
                                                                                    <OverlayTrigger
                                                                                        placement='top'
                                                                                        overlay={<Tooltip>Consumption of deepgram used vs the total deepgram consumption that can be done.</Tooltip>}
                                                                                    >
                                                                                        <span className='ms-2'><img src={imagePath('/images/info_icon.svg')} alt='' /></span>
                                                                                    </OverlayTrigger>
                                                                                </h6>
                                                                                <div className='progress_nu d-flex justify-content-between'>
                                                                                    <div>{billingData?.consumption?.deepgram_consumption}</div>
                                                                                    <div>{billingData?.billing_plan?.deepgram_limit - billingData?.consumption?.deepgram_consumption} Left</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="progress" style={{ height: "5px" }}>
                                                                            <ProgressBar className="mb-3" now={100} variant="success" style={{ width: getProgress(billingData?.consumption?.deepgram_consumption, billingData?.billing_plan?.deepgram_limit), height: "5px" }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-6'>
                                                                        <div className="wrapper-progress">
                                                                            <div className="percentage-progress ">
                                                                                <h6 className='mb-2'>GPT Consumption
                                                                                    <OverlayTrigger
                                                                                        placement='top'
                                                                                        overlay={<Tooltip>Consumption of GPT tokens out of the total tokens available.</Tooltip>}
                                                                                    >
                                                                                        <span className='ms-2'><img src={imagePath('/images/info_icon.svg')} alt='' /></span>
                                                                                    </OverlayTrigger>
                                                                                </h6>
                                                                                <div className='progress_nu d-flex justify-content-between'>
                                                                                    <div>{billingData?.consumption?.gpt_consumption}</div>
                                                                                    <div>{billingData?.billing_plan?.gpt_limit - billingData?.consumption?.gpt_consumption} Left</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="progress" style={{ height: "5px" }}>
                                                                            <ProgressBar className="mb-3" now={100} variant="success" style={{ width: getProgress(billingData?.consumption?.gpt_consumption, billingData?.billing_plan?.gpt_limit), height: "5px" }} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* plan tracker */}
                                                        <div className=''>
                                                            <div>
                                                                <div className='two_column_title'>
                                                                    <h3 className="page_title_sub_head">Usage</h3>
                                                                    <div className='table_exp_area d-flex'>
                                                                        <Dropdown className='table_exp_ddn'>
                                                                            {/* <Dropdown.Toggle as={Button} variant="secondary" type="button" id="dropdownMenuButton ">
                                                                                <img src={process.env.PUBLIC_URL + '/images/filter_icon.svg'} className='me-2' /> Filter
                                                                            </Dropdown.Toggle> */}
                                                                            <Dropdown.Menu aria-labelledby="dropdownMenuButton">
                                                                                <Dropdown.Item ><Link to="">Last 12 Months</Link></Dropdown.Item>
                                                                                <Dropdown.Item><Link to="">Last 6 Months</Link></Dropdown.Item>
                                                                                <Dropdown.Item><Link to="">Last 12 Months</Link></Dropdown.Item>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                        {/* <a className='btn exp_btn ms-3' href=''><span><img src={process.env.PUBLIC_URL + '/images/excel_icon2.svg'} /></span> Export Data</a> */}
                                                                    </div>
                                                                    {/* <button
                                                                        className='btn bordered_btn'                                                                    >
                                                                        Export Data
                                                                    </button> */}

                                                                </div>
                                                                <p className='subtitle_color mt-1 mb-0'>Analyse past usage of various features as part of your plan</p>
                                                            </div>
                                                            {/* <div className='row mt-3'>
                                                                <div className='col-lg-3 card_legends_box'>
                                                                    <div className='card_legends card w-100'>
                                                                        <h6 className='mb-2'>Transcription Accuracy</h6>
                                                                        <h4 className='mb-0'>88%</h4>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-3 card_legends_box'>
                                                                    <div className='card_legends card w-100'>
                                                                        <h6 className='mb-2'>Transcription Accuracy</h6>
                                                                        <h4 className='mb-0'>88%</h4>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-3 card_legends_box'>
                                                                    <div className='card_legends card w-100'>
                                                                        <h6 className='mb-2'>Transcription Accuracy</h6>
                                                                        <h4 className='mb-0'>88%</h4>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-3 card_legends_box'>
                                                                    <div className='card_legends card w-100'>
                                                                        <h6 className='mb-2'>Transcription Accuracy</h6>
                                                                        <h4 className='mb-0'>88%</h4>
                                                                    </div>
                                                                </div>

                                                            </div> */}
                                                        </div>
                                                        {/* graph */}
                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <div className='dash_graph'>
                                                                    <Card>
                                                                        <Card.Header className='d-flex justify-content-between card_header'>
                                                                            <Card.Header.Title className='w-100'>
                                                                                <div className='two_column_title'>
                                                                                    <div className='lft_title'>
                                                                                        <h5>
                                                                                            Tracked Users
                                                                                        </h5>
                                                                                    </div>

                                                                                </div>
                                                                            </Card.Header.Title>
                                                                        </Card.Header>
                                                                        <Card.Body className='text-center'>

                                                                            <BarChart data={tracked_users}/>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <div className='dash_graph'>
                                                                    <Card>
                                                                        <Card.Header className='d-flex justify-content-between card_header'>
                                                                            <Card.Header.Title className='w-100'>
                                                                                <div className='two_column_title'>
                                                                                    <div className='lft_title'>
                                                                                        <h5>
                                                                                            Total Audit Calls
                                                                                        </h5>
                                                                                    </div>

                                                                                </div>
                                                                            </Card.Header.Title>
                                                                        </Card.Header>
                                                                        <Card.Body className='text-center'>

                                                                        <BarChart data={audit_calls}/>
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                            {/* <div className='col-lg-6'>
                                                                <div className='dash_graph'>
                                                                    <Card>
                                                                        <Card.Header className='d-flex justify-content-between card_header'>
                                                                            <Card.Header.Title className='w-100'>
                                                                                <div className='two_column_title'>
                                                                                    <div className='lft_title'>
                                                                                        <h5>
                                                                                            Deepgram Consumption
                                                                                        </h5>
                                                                                    </div>

                                                                                </div>
                                                                            </Card.Header.Title>
                                                                        </Card.Header>
                                                                        <Card.Body className='text-center'>

                                                                            graph space 3
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-6'>
                                                                <div className='dash_graph'>
                                                                    <Card>
                                                                        <Card.Header className='d-flex justify-content-between card_header'>
                                                                            <Card.Header.Title className='w-100'>
                                                                                <div className='two_column_title'>
                                                                                    <div className='lft_title'>
                                                                                        <h5>
                                                                                            GPT Consumption
                                                                                        </h5>
                                                                                    </div>

                                                                                </div>
                                                                            </Card.Header.Title>
                                                                        </Card.Header>
                                                                        <Card.Body className='text-center'>

                                                                            graph space 4
                                                                        </Card.Body>
                                                                    </Card>
                                                                </div>
                                                            </div> */}
                                                        </div>
                                                    </div>

                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Tab.Container>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* edit plan modal */}
            <Modal
                show={show4}
                onHide={() => {
                    handleClose4();
                }}
                backdrop='static'
                keyboard={false}
                className='edit_plan_modal'>
                <Modal.Header >
                    <h4 className='text-primary'>
                        Edit Plan
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <form className='form_style_comman'>
                        <div className='row'>
                            <div className='col-lg-4 form-group'>
                                <label className='two_column_title'>
                                    <span>Billing</span>
                                    <p><i>mins per month</i></p>
                                </label>
                                <input type='text' className='form-control' />
                            </div>
                            <div className='col-lg-4 form-group'>
                                <label>Billing Cycle</label>
                                <DateRangePickerComp

                                />
                            </div>
                            <div className='col-lg-4 form-group'>
                                <label>Payment<sup class="sup_manatory">*</sup></label>
                                <input type='text' className='form-control' />
                            </div>
                            <div className='text-end mt-3 col-12'>
                                <Button

                                    className='btn bordered_btn me-3'
                                    onClick={() => {
                                        handleClose4()
                                    }}>
                                    Discard
                                </Button>
                                <Button
                                    variant='primary'
                                    type='button'
                                >

                                    Save
                                </Button>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            {/*  */}
            <Modal
                show={show3}
                onHide={() => {
                    handleClose4();
                }}
                backdrop='static'
                keyboard={false}
                className='edit_plan_modal'>
                <Modal.Header >
                    <h4 className='text-primary'>
                        Generate Invoice
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <form className='form_style_comman'>
                        <div className='row'>
                            <div className='col-lg-4 form-group'>
                                <label>Invoice No<sup class="sup_manatory">*</sup></label>
                                <input type='text' className='form-control' placeholder='Invoice No' />
                            </div>
                            <div className='col-lg-4 form-group'>
                                <label>Billing Date<sup class="sup_manatory">*</sup></label>
                                <Flatpickr
                                    options={{ minDate: 'today' }}
                                    className='form-control flatpickrdate'
                                    placeholder='Select Date... '
                                />
                            </div>
                            <div className='col-lg-4 form-group'>
                                <label>Status<sup class="sup_manatory">*</sup></label>
                                <select className='form-control'>
                                    <option>Select</option>
                                    <option>Pending</option>
                                    <option>Paid</option>
                                </select>
                            </div>
                            <div className='col-lg-4 form-group'>
                                <label>Amount<sup class="sup_manatory">*</sup></label>
                                <input type='text' className='form-control' placeholder='Enter Amount' />
                            </div>
                            <div className='col-lg-4 form-group'>
                                <label className='two_column_title'>
                                    <span>Plan</span>
                                    <p><i>mins per month</i></p>
                                </label>
                                <input type='text' className='form-control' placeholder='Enter value' />
                            </div>

                            <div className='text-end mt-3 col-12'>
                                <Button

                                    className='btn bordered_btn me-3'
                                    onClick={() => {
                                        handleClose3()
                                    }}>
                                    Discard
                                </Button>
                                <Button
                                    variant='primary'
                                    type='button'
                                >

                                    Save
                                </Button>
                            </div>

                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Billing