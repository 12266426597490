import React, { useState, useRef, useEffect } from 'react';
import { Breadcrumb, Button, Modal } from 'react-bootstrap';
import { ErrorNotify, imagePath, infoNotify, successNotify } from '../../CommonLogic';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import * as AuthSelector from '../../store/auth/selectors';
import { useNavigate, useParams } from 'react-router-dom';
import { client } from '../../services/apiService'
import PaginationBar from '../partials/PaginationBar';

const OrganisationFinance = () => {
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const organisation = useSelector(AuthSelector.organization);
    const permissions = useSelector(AuthSelector.accessPermissions);
    const userInfo = useSelector(AuthSelector.loggedInUser);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const { id } = useParams();

    // States for organisations
    const [organisationOriginalList, setorganisationOriginalList] = useState([]); // Original organisation list
    const [organisationList, setorganisationList] = useState([]); // Original organisation list
    const [organisationFilteredList, setorganisationFilteredList] = useState([]); // Filtered organisation list

    // States for departments
    const [departmentOriginalList, setDepartmentOriginalList] = useState([]); // Original department list
    const [departmentFilteredList, setDepartmentFilteredList] = useState([]); // Filtered department list

    const [searchString, setSearchString] = useState(''); // Search input
    const isOrganisation = useRef(true); // Flag to determine search type (organisation or department)
    const isDepartment = useRef(false); // Flag to determine search type (organisation or department)
    const parentId = useRef(null); // Flag to determine (organisation or department) id
    const organisationId = useRef(null); // Flag to determine organisation id
    const apiLoader = useRef(false);
    const [parentDepartment, setParentDepartment] = useState('Organisation')
    const [pageSize, setPageSize] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(10);
    const [isloading, setIsLoading] = useState(false)
    const handlePageSizeChange = (newSize, data = []) => {
        setPageSize(newSize);
        if (isDepartment.current) {
            const startIndex = (currentPage - 1) * pageSize;
            const endIndex = startIndex + +pageSize;
            const departmentsArray = data || [];
            setPageCount(Math.max(Math.ceil(data.length / pageSize), 1));
            setDepartmentFilteredList(departmentsArray.slice(startIndex, endIndex));
            return;
        }
        setCurrentPage(1);
    };

    const handleBulkPageButton = (page) => {
        setCurrentPage(page);
    };
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        url: '',
        industry: '',
        pocName: '',
        pocContact: '',
    });

    // State for warnings and validation
    const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        url: '',
        contact: '',
        form: '',
    });
    useEffect(() => {
        if (id && organisation?.organisationName) {
            const [parent, identity, ID] = id.split("-");
            if (identity == 'org') {
                setParentDepartment('subOrganisation');
            } else if (identity == 'dept') {
                setParentDepartment('Department');
            }
            if (parent && parent !== 'all') {
                organisationId.current = parent;
            }
            if (identity && ID) { parentId.current = ID; fetchDetails(identity, ID) } else if (orgStrId) {
                fetchOrganisationList();
            }
        }
    }, [id, orgStrId, pageSize, currentPage, organisation])

    async function fetchDetails(identity, id) {
        setIsLoading(true);
        if (identity == 'org') {
            try {
                const response = await client.get(`/organisations/${id}?populate[Departments][filters][Is_Active][$eq]=true&pagination[page]=${currentPage || 1}&pagination[pageSize]=${pageSize}`);
                if (response.success) {
                    setIsLoading(false)
                    if (organisation?.organisationName !== 'Omind' && response?.data?.attributes?.Organisation_Name !== organisation?.organisationName) {
                        ErrorNotify('Error fetching Organisation');
                        navigate(-1);
                        fetchOrganisationList();
                        return;
                    }
                    updateOrganisationState(response.data.attributes.Departments.data);
                    if(parentDepartment!=='Organisation')setCurrentPage(1);
                    isOrganisation.current = true;
                    isDepartment.current = true;
                }
            } catch (error) {
                setIsLoading(false)
                ErrorNotify('Error fetching organisations');
            }
        } else if (identity == 'dept') {
            setIsLoading(true)
            try {
                const response = await client.get(`/departments/${id}?populate[Organisation][fields][0]=Organisation_Name&populate[Departments][filters][Is_Active][$eq]=true&pagination[page]=${currentPage || 1}&pagination[pageSize]=${pageSize}&populate[Departments][populate][Departments][filters][Is_Active][$eq]=true`);
                if (response.success) {
                    setIsLoading(false)
                    if (organisation?.organisationName !== 'Omind' && response.data?.attributes?.Departments?.data.length == 0) {
                        infoNotify('No Department Found');
                        navigate(-1)
                        return;
                    }
                    if (organisation?.organisationName !== 'Omind' && response.data?.attributes?.Organisation?.data?.attributes?.Organisation_Name !== organisation?.organisationName) {
                        ErrorNotify('Error fetching Department');
                        navigate(-1);
                        return;
                    }
                    isOrganisation.current = false;
                    isDepartment.current = true;
                    setDepartmentOriginalList(response.data?.attributes?.Departments?.data); // Store original department data
                    handlePageSizeChange(pageSize,response.data?.attributes?.Departments?.data);
                    // setDepartmentFilteredList(response.data?.attributes?.Departments?.data); // Initialize filtered list
                }
            } catch (error) {
                setIsLoading(false)
                ErrorNotify('Error fetching organisations');
            }
        }
    }

    // Fetch all organisations and update both original and filtered lists
    async function fetchOrganisationList() {
        const urlChoice = organisation?.organisationName == 'Omind' ? `/organisations?populate[Departments][filters][Is_Active][$eq]=true&pagination[page]=${currentPage || 1}&pagination[pageSize]=${pageSize}` : `/organisations?filters[Org_str_id][$eq]=${orgStrId}&populate[Departments][filters][Is_Active][$eq]=true`;
        try {
            setIsLoading(true);
            const response = await client.get(urlChoice);
            if (response.success) {
                if (response?.meta?.pagination?.pageCount) { setPageCount(response?.meta?.pagination?.pageCount);setCurrentPage(response?.meta?.pagination?.page?response?.meta?.pagination?.page:1) }

                setorganisationOriginalList(response.data); // Store original organisation data
                setorganisationFilteredList(response.data); // Initialize filtered list
                setorganisationList(response.data);
                parentId.current = null;
                isOrganisation.current = true;
                isDepartment.current = false;
                setIsLoading(false)
            }
        } catch (error) {
            setIsLoading(false);
            ErrorNotify('Error fetching organisations');
        }
    }

    // Search organisations or departments based on search input
    function SearchEntity() {
        if (searchString.trim() === '') {
            // If search input is empty, reset the filtered list to the original list
            if (isOrganisation.current) {
                setorganisationFilteredList(organisationOriginalList);
            } else {
                // setDepartmentFilteredList(departmentOriginalList);
                handlePageSizeChange(pageSize,departmentOriginalList);
            }
            return;
        }

        // Filter organisations or departments based on the search input
        if (isOrganisation.current) {
            const filteredorganisations = organisationOriginalList.filter((org) =>
                org.attributes?.Organisation_Name?.toLowerCase().includes(searchString.toLowerCase()) || org.attributes?.Department_Name?.toLowerCase().includes(searchString.toLowerCase())
            );
            setorganisationFilteredList(filteredorganisations); // Update the filtered state
        } else {
            let filteredDepartments = JSON.parse(JSON.stringify(departmentOriginalList)); // Create a shallow copy

            // Filter the departments based on the search string
            filteredDepartments = filteredDepartments.filter((department) =>
                department.attributes?.Department_Name.toLowerCase().includes(searchString.toLowerCase())
            );
            // Only update the state if there are any filtered departments
            if (filteredDepartments.length > 0) {
                handlePageSizeChange(pageSize,filteredDepartments); // Update the filtered state
            } else {
                handlePageSizeChange(pageSize,[]); // Reset if no departments match
            }
        }
    }

    // Updating 2 State of organisation Child Department
    function updateOrganisationState(Department) {
        setorganisationFilteredList(Department);
        setorganisationOriginalList(Department);
        isDepartment.current = true;
    }

    const handleCloseModal = () => {
        setShowModal(false);
        setFormErrors({
            name: '',
            email: '',
            url: '',
            pocContact: '',
            form: '',
        });
        setFormData({
            name: '',
            email: '',
            url: '',
            industry: '',
            pocName: '',
            pocContact: '',
        });
    };

    const handleShowModal = () => setShowModal(true);

    const validateEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const validateUrl = (url) => /^(https?:\/\/|www\.)[^\s/$.?#].[^\s]*$/.test(url);
    const validateContact = (contact) => /^\+?[0-9]+$/.test(contact);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleAddOrganisation = async () => {
        if (apiLoader.current) {
            return
        }
        const { name, email, url, industry, pocName, pocContact } = formData;

        // Check if all fields are filled
        if (!name || !email || !url || !industry || !pocName || !pocContact) {
            setFormErrors((prev) => ({ ...prev, form: 'Please fill all mandatory fields.' }));
            return;
        }

        // Validate email
        if (!validateEmail(email)) {
            setFormErrors((prev) => ({ ...prev, email: 'Please enter a valid email address.' }));
            return;
        }

        // Validate URL
        if (!validateUrl(url)) {
            setFormErrors((prev) => ({ ...prev, url: 'Please enter a valid URL (starting with http:// or https://).' }));
            return;
        }

        // Validate contact
        if (!validateContact(pocContact)) {
            setFormErrors((prev) => ({ ...prev, pocContact: 'Please enter a valid contact number.' }));
            return;
        }

        const organisation = isOrganisation.current;
        const departmentType = parentDepartment;

        if (departmentType == 'Organisation' && organisation?.organisationName !== 'Omind') {

            // Check if the organization name already exists
            if (organisationList.some(org => org.attributes?.Organisation_Name?.toLowerCase() === name.toLowerCase())) {
                setFormErrors((prev) => ({ ...prev, name: 'Organization name already exists.' }));
                return;
            }
        } else if (departmentType == 'subOrganisation') {
            // Check if the subOrganization name already exists
            if (organisationOriginalList.some(org => org.attributes?.Department_Name?.toLowerCase() === name.toLowerCase())) {
                setFormErrors((prev) => ({ ...prev, name: 'subOrganization name already exists.' }));
                return;
            }
        } else if (departmentType == 'Department') {
            // Check if the department name already exists
            if (departmentOriginalList.attributes?.Departments?.data.some(org => org.attributes?.Department_Name?.toLowerCase() === name.toLowerCase())) {
                setFormErrors((prev) => ({ ...prev, name: 'Department name already exists.' }));
                return;
            }
        }

        // Clear errors and proceed with form submission logic
        setFormErrors({
            name: '',
            email: '',
            url: '',
            contact: '',
            form: '',
        });

        const organisationPayload = {
            data: {
                Organisation_Name: name,
                Work_Email: email,
                Url: url,
                Industry: industry,
                Client_POC_Name: pocName,
                Client_POC_Contact: pocContact,
            },
            parentId: parentId.current,
            identity: departmentType
        }
        const departmentPayload = {
            data: {
                Department_Name: name,
                Work_Email: email,
                Url: url,
                Industry: industry,
                Client_POC_Name: pocName,
                Client_POC_Contact: pocContact,
                Organisation: {
                    connect: [
                        {
                            id: organisationId.current,
                        },
                    ],
                }
            },
            parentId: parentId.current,
            identity: departmentType,
        }

        try {
            apiLoader.current = true;
            const response = await client.post('/organisations/profile', departmentType == 'Organisation' ? organisationPayload : departmentPayload);
            if (response.success) {
                successNotify(`${departmentType == 'subOrganisation' ? 'Sub-Organization' : departmentType} added successfully!`);
                apiLoader.current = false;
                handleCloseModal();
                if (departmentType == "Organisation") { fetchOrganisationList() } else if (id) {
                    const [parent, identity, ID] = id.split("-");
                    if (identity && ID) { fetchDetails(identity, ID) }
                } else {
                    window.location.reload();
                }
            }
            else {
                throw response
            }
        } catch (error) {
            ErrorNotify(`Failed to add ${departmentType}.`);
            apiLoader.current = false;
        }
    };


    return (
        <>
            <div className='page-wrapper'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-sm-12 content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className=''>
                                    <div className='row'>
                                        <div className='col-md-12'>
                                            <div className='breadcrumb_box'>
                                                <Breadcrumb>
                                                    <Breadcrumb.Item
                                                        as='li'
                                                        href='/'
                                                    >
                                                        <img
                                                            src={imagePath(
                                                                '/images/home_icon_pagig.svg'
                                                            )}
                                                            alt=''
                                                        />
                                                    </Breadcrumb.Item>
                                                    <Breadcrumb.Item
                                                        as='li'
                                                        aria-current='page'
                                                        onClick={() => { navigate(`/o/${orgStrId}/setup`) }}
                                                    >
                                                        Settings
                                                    </Breadcrumb.Item>
                                                    <Breadcrumb.Item
                                                        as='li'
                                                        active
                                                        aria-current='page'
                                                    >
                                                        Organisations
                                                    </Breadcrumb.Item>
                                                </Breadcrumb>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='page_title_box two_column_title'>
                                        <h3 className='page_title'>
                                            <span className='pe-3'>
                                                <button className='btn btn_transparent'>
                                                    <img
                                                        onClick={() => { navigate(-1) }}
                                                        src={imagePath('/images/back_arrow.svg')}
                                                        alt='back button'
                                                    />
                                                </button>
                                            </span>{' '}
                                            Organisations
                                        </h3>
                                        {organisation?.organisationName == 'Omind' ?
                                            (<button
                                                className='btn comman_btn'
                                                onClick={() => {
                                                    handleShowModal();
                                                }}
                                            >
                                                {!isDepartment.current ? "Add Organisation" : "Add Department"}
                                            </button>) : ("")}
                                    </div>
                                </div>
                                {/* search */}
                                <div className='d-flex justify-content-end'>
                                    <div className='useronboard_search ms-3'>
                                        <input
                                            className='form-control'
                                            type='text'
                                            placeholder={isOrganisation.current ? "Search Organisation" : "Search Department"}
                                            value={searchString}
                                            onChange={(e) => setSearchString(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    SearchEntity();
                                                }
                                            }}
                                        />
                                        <button className='diy_emp_search_btn' onClick={SearchEntity}>
                                            <i
                                                className='fa fa-search'
                                                aria-hidden='true'
                                            ></i>
                                        </button>
                                    </div>
                                </div>

                                <div className='table_style_comman mt-3'>
                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead>
                                                <tr>
                                                    <th>Sl. no</th>
                                                    <th>Name</th>
                                                    <th>Work Email</th>
                                                    <th>URL</th>
                                                    <th>Industry</th>
                                                    <th>POC Name</th>
                                                    <th>POC Contact</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {isloading ? (
                                                    [0, 1, 2, 3, 4,5].map(temp => {
                                                        return (
                                                            <tr
                                                                className='p_relative'
                                                                key={'row-ld-' + temp}
                                                            >
                                                                {[0, 1, 2, 3, 4, 5, 6, 7].map(
                                                                    temp2 => (
                                                                        <td
                                                                            key={
                                                                                'row-ld-td-' + temp2
                                                                            }
                                                                        >
                                                                            <div
                                                                                style={{
                                                                                    height: '10px',
                                                                                }}
                                                                                className='shimmer'
                                                                            >
                                                                                &nbsp;
                                                                            </div>
                                                                        </td>
                                                                    )
                                                                )}
                                                            </tr>
                                                        );
                                                    })
                                                ) : isOrganisation.current ? (
                                                    organisationFilteredList.map((org, i) => (
                                                        <tr key={`organisationList ${i + 1}`}>
                                                            <td>{currentPage && pageSize
                                                                ? (currentPage - 1) *
                                                                pageSize +
                                                                (i + 1)
                                                                : i + 1}</td>
                                                            <td className='link_underline' onClick={() => {
                                                                parentId.current = org.id;
                                                                if (isOrganisation.current && !(isOrganisation.current && isDepartment.current)) { organisationId.current = org.id; }
                                                                navigate(`/o/${orgStrId}/setup/organisation-finance/${organisationId.current ? organisationId.current : 'all'}-${isOrganisation.current && !(isOrganisation.current && isDepartment.current) ? 'org-' + org?.id : 'dept-' + org?.id}`)
                                                            }}>
                                                                {org?.attributes?.Organisation_Name ? org?.attributes?.Organisation_Name : org.attributes?.Department_Name ? org.attributes?.Department_Name : 'Not Found'}
                                                            </td>
                                                            <td>{org?.attributes?.Work_Email ? org?.attributes?.Work_Email : 'Email not added'}</td>
                                                            <td>{org?.attributes?.Url ? org?.attributes?.Url : 'URL not added'}</td>
                                                            <td>{org?.attributes?.Industry ? org?.attributes?.Industry : 'Industry not added'}</td>
                                                            <td>{org.attributes?.Client_POC_Name ? org.attributes?.Client_POC_Name : 'Name not added'}</td>
                                                            <td>{org.attributes?.Client_POC_Contact ? org.attributes?.Client_POC_Contact : 'Contact not added'}</td>
                                                            <td>
                                                                <Link to={`/o/${orgStrId}/setup/organisation-profile/${isOrganisation.current && !(isOrganisation.current && isDepartment.current) ? 'org-' + org?.id : 'dept-' + org?.id}`} className='link_text fw_600'>View</Link>
                                                            </td>
                                                        </tr>
                                                    ))
                                                ) : (
                                                    departmentFilteredList?.map((dept, i) => (
                                                        <tr key={`DepartmentList ${i + 1}`}>
                                                            <td>{currentPage && pageSize
                                                                ? (currentPage - 1) *
                                                                pageSize +
                                                                (i + 1)
                                                                : i + 1}</td>
                                                            <td className='link_underline' onClick={() => { parentId.current = dept.id; navigate(`/o/${orgStrId}/setup/organisation-finance/${organisationId.current ? organisationId.current : 'all'}-dept-${dept?.id}`) }}>
                                                                {dept?.attributes?.Department_Name || 'Department Not Found'}
                                                            </td>
                                                            <td>{dept?.attributes?.Work_Email ? dept?.attributes?.Work_Email : 'Email not added'}</td>
                                                            <td>{dept?.attributes?.Url ? dept?.attributes?.Url : 'URL not added'}</td>
                                                            <td>{dept?.attributes?.Industry ? dept?.attributes?.Industry : 'Industry not added'}</td>
                                                            <td>{dept?.attributes?.Client_POC_Name ? dept?.attributes?.Client_POC_Name : 'Name not added'}</td>
                                                            <td>{dept?.attributes?.Client_POC_Contact ? dept?.attributes?.Client_POC_Contact : 'Contact not added'}</td>
                                                            <td>
                                                                <Link to={`/o/${orgStrId}/setup/organisation-profile/dept-${dept?.id}`} className='link_text fw_600'>View</Link>
                                                            </td>

                                                        </tr>
                                                    ))
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*Pagination*/}
                <PaginationBar
                    base={`/o/${orgStrId}/setup/organisation-finance/all`}
                    nPages={pageCount}
                    currentPage={currentPage}
                    pageSize={pageSize}
                    setCurrentPage={setCurrentPage}
                    onPageSizeChange={handlePageSizeChange}
                    forBulk={false}
                    handleBulkPageButton={handleBulkPageButton}
                />
            </div>
            {/*  */}
            <Modal
                show={showModal}
                onHide={handleCloseModal}
                backdrop='static'
                keyboard={false}
                className='edit_plan_modal'
            >
                <Modal.Header>
                    <h4 className='text-primary'>{!isDepartment.current ? "Add Organisation" : "Add Department"}</h4>
                </Modal.Header>
                <Modal.Body>
                    <form className='form_style_comman'>
                        <div className='row'>
                            {[
                                { label: 'Name', name: 'name', required: true },
                                { label: 'Work Email', name: 'email', required: true, type: 'email' },
                                { label: 'URL', name: 'url', required: true },
                                { label: 'Industry', name: 'industry', required: true },
                                { label: 'POC Name', name: 'pocName', required: true },
                                { label: 'POC Contact', name: 'pocContact', required: true },
                            ].map(({ label, name, required, type = 'text' }) => (
                                <div key={name} className='col-lg-4 form-group'>
                                    <label>
                                        {label}
                                        {required && <sup className='sup_manatory'>*</sup>}
                                    </label>
                                    <input
                                        type={type}
                                        className='form-control'
                                        name={name}
                                        placeholder={label}
                                        value={formData[name]}
                                        onChange={handleInputChange}
                                    />
                                    {formErrors[name] && <small className='text-danger'>{formErrors[name]}</small>}
                                </div>
                            ))}
                            <div className='text-end mt-3 col-12'>
                                {formErrors.form && <small className='text-danger'>{formErrors.form}</small>}
                                <Button className='btn bordered_btn me-3' onClick={handleCloseModal}>
                                    Discard
                                </Button>
                                <Button variant='primary' type='button' onClick={handleAddOrganisation}>
                                    Add
                                </Button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default OrganisationFinance;
