import React, { useEffect, useRef, useState } from 'react';
import {
    Breadcrumb,
    Modal,
    Tab,
    Nav,
    Button,
    Spinner,
} from 'react-bootstrap';
import 'react-dropdown-tree-select/dist/styles.css';
import {
    ErrorNotify,
    imagePath,
    loadingData,
    successNotify,
    toolTipHOC,
} from '../../CommonLogic';
import DIYSideNav from './DIYSideNav';
import * as XLSX from 'xlsx';
import { client } from '../../services/apiService';
import { validateToken } from '../../store/auth/reducers';
import { useDispatch, useSelector } from 'react-redux';
import UserForm from './UserForm';
import PaginationBar from '../partials/PaginationBar';
import { useSearchParams, useNavigate } from 'react-router-dom';
import * as AuthSelector from '../../store/auth/selectors';
import csvDownload from 'json-to-csv-export';
import * as moment from 'moment';
import BulkUploadOptions from './BulkUploadOptions';
import Select from 'react-select';

const DiyLandingScreen = () => {
    const orgStrId = useSelector(AuthSelector.orgStrId);
    const organization = useSelector(AuthSelector.organization);
    const permissions = useSelector(AuthSelector.accessPermissions);
    const obsOrg = useSelector(AuthSelector.obs);
    const [show4, setShow4] = useState(false);
    const handleCloseSingleUserModal = () => {
        setShow4(false);
        setShowUserEditModal(false);
    };
    const handleShow4 = () => setShow4(true);
    const handleShow3 = () => setShow3(true);
    const forOmindPersona = organization?.organisationName == 'Omind'
    const [show3, setShow3] = useState(false);
    const [designation, setDesignation] = useState([]);
    const [supervisor, setSupervisor] = useState([]);
    const [dashboardRole, setDashboardRole] = useState([]);
    const [dashboardUserData, setDashboardUserData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [dashboardUserDataCopy, setDashboardUserDataCopy] = useState([]);
    const [showUserEditModal, setShowUserEditModal] = useState(false);
    const [userFailedData, setUserFailedData] = useState([]);
    const [pageSize, setPageSizeChange] = useState(10);
    const [pageSizeForBulk, setPageSizeChangeForBulk] = useState(10);
    const [numPages, setNumPages] = useState(1);
    const [numPagesForBulk, setNumPagesForBulk] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPageForBulk, setCurrentPageForBulk] = useState(1);
    const [organisationId,setOrganisationId] = useState(forOmindPersona ? "" : '{{org-id}}');
    const [totalUser, setTotalUser] = useState(10);
    const [totalUserForBulk, setTotalUserForBulk] = useState(10);
    const [totalUserCopy, setTotalUserCopy] = useState(10);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);
    const [userData, setUserData] = useState({});
    const [bulkUserData, setBulkUserData] = useState([]);
    const [bulkUserDataCopy, setBulkUserDataCopy] = useState([]);
    const [searchedText, setSearchedText] = useState('');
    const [optionBulkUser, setOptionBulkUser] = useState({});
    const [validated, setValidated] = useState(false);
    const [loadingButton, setLoadingButton] = useState(false);
    const selectedOption = useRef({});
    const [bulkKeysExist, setBulkKeysExist] = useState(false);
    const [forBulk, setForBulk] = useState(false);
    const [organizationOption, setOrganizationOption] = useState([]);
    const [selectedOrganization, setSelectedOrganization] = useState({});
    const [obsForOrganization, setObsForOrganization] = useState(obsOrg);

    useEffect(() => {
        setCurrentPage(searchParams.get('page') || 1);
    }, [searchParams]);

    useEffect(() => {
        if (
            currentPage == searchParams.get('page') ||
            !searchParams.get('page')
        ) {
            if (!forBulk) {
                fetchData(organisationId);
            }
        }
    }, [currentPage, pageSize]);

    const getOrganization = async()=>{
        const res = await client.get('/organisations');
        setOrganizationOption(res?.data);
        const organisationData = res?.data?.find(
            (el) => el?.attributes?.Organisation_Name === organization?.organisationName
        );
        setSelectedOrganization({
            value: organisationData?.id,
            label: organisationData?.attributes?.Organisation_Name
        })
        setOrganisationId(organisationData?.id);
    }
    
    const getUsers = async () => {
        await fetchData(organisationId);
    };

     useEffect(() => {
        if(organisationId && forOmindPersona){
            getUsers();
        }
     }, [organisationId]);

    useEffect(() => {
        forOmindPersona && getOrganization();
        getUsers();
        if (localStorage.getItem('userToken')) {
            dispatch(validateToken());
        } else {
            navigate('/login');
        }
    }, [orgStrId]);

    function handleChangeSingleUser(e) {
        const { name, value } = e.target;
        setOptionBulkUser({ ...optionBulkUser, [name]: value });

        selectedOption.current = { ...selectedOption.current, [name]: value }
        const keysToCheck = ["departments", "user_role", "organistation_roles"];
        const allKeysValid = keysToCheck.every(key => key in selectedOption.current && selectedOption.current[key]);
        setBulkKeysExist(allKeysValid);
    }

    const addUserEditDataFunc = el => {
        const departmentIds = el?.role_map?.[0]?.departments
            ?.map(department => department.id)
            .join(',');
        const data = {
            id: el?.id,
            confirmed: true,
            first_name: el?.first_name,
            last_name: el?.last_name,
            employee_id: el?.employee_id,
            username: el?.username,
            unique_id: el?.username,
            email: el?.email,
            staus: el?.blocked,
            blocked: el?.blocked,
            password: el?.password,
            Supervised_By: el?.Supervised_By?.id,
            departments: departmentIds,
            organistation_roles:
                el?.role_map?.[0]?.organistation_roles?.[0]?.id,
            user_role: el?.role_map?.[0]?.user_role?.id,
        };
        setUserData(data);
    };

    const handleClose3 = () => {
        setShow3(false);
        setBulkUserData([]);
        setShowUserEditModal(false);
        setUserFailedData([]);
    };

    const handleFileUpload = e => {
        const file = e.target.files[0];
        if (file) {
            const allowedExtensions = ['.xlsx', '.csv'];
            const fileName = file.name.toLowerCase();
            const fileExtension = fileName.substring(fileName.lastIndexOf('.'));
            if (allowedExtensions.includes(fileExtension)) {
                readUploadedFile(file);
            }
        }
    };

    const readUploadedFile = file => {
        const reader = new FileReader();
        reader.readAsBinaryString(file);

        reader.onload = e => {
            const data = e.target.result;
            const workbook = XLSX.read(data, { type: 'binary' });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            let excelData = XLSX.utils.sheet_to_json(worksheet, {
                header: 1,
                raw: false,
                cellDates: false,
                rawNumbers: true,
            });
            excelData = excelData.filter(row => row.length > 0);
            let dataToSet = processExcelData(excelData, currentPageForBulk, pageSizeForBulk);
            const totalCount = dataToSet?.length
            let supervisorId = {}
            const failedSupervisorEmailList = []
            const checkSupervisor = async () => {
                for (let i = 0; i < dataToSet?.length; i++) {
                    const bulkUserData = dataToSet[i];

                    if (bulkUserData?.[3]?.name === 'Supervised_By') {
                        if (supervisorId?.[bulkUserData?.[3]?.value]) {
                            continue;
                        } else {
                            const res = await client.get(
                                `/users?filters[email][$eq]=${bulkUserData?.[3]?.value}`
                            );
                            const email = bulkUserData?.[3]?.value + '';
                            const userId = res?.data?.[0]?.id;
                            supervisorId = {
                                ...supervisorId,
                                [email]: userId,
                            };
                            if (res.data?.length === 0) {
                                supervisorId = {
                                    ...supervisorId,
                                    [email]: 9999999999,
                                };
                                email != 'undefined' &&
                                    failedSupervisorEmailList.push(email);
                            }
                        }
                    }
                }
                if (
                    failedSupervisorEmailList?.length > 0 &&
                    failedSupervisorEmailList[0] != 'undefined'
                ) {
                    setIsButtonDisabled(true);
                    ErrorNotify(
                        `${failedSupervisorEmailList.join(', ')} Supervisor does not exist. Please use existing user for Supervisor!`
                    );
                } else {
                    setIsButtonDisabled(false);
                }
                dataToSet = dataToSet.map(user => {
                    return user.map(field => {
                        if (
                            field.name === 'Supervised_By' &&
                            failedSupervisorEmailList.includes(field.value)
                        ) {
                            field.valid = false;
                        }
                        return field;
                    });
                });
            };

            checkSupervisor();

            setBulkUserData(dataToSet);
            setBulkUserDataCopy(dataToSet?.slice(0, 10));
            setNumPagesForBulk(Math?.ceil(totalCount / pageSizeForBulk))
            setTotalUserForBulk(totalCount);
        };
    };

    function processExcelData(excelData, currentPageForBulk, pageSizeForBulk) {
        if(excelData.length<=0){
            return [];
        }
        const dataToSet = [];
        const validKeys = [
            "first_name",
            "last_name",
            "employee_id",
            "Supervised_By",
            "email",
            "unique_id"
        ];
        const arrayMap = excelData.splice(0, 1)[0];
        excelData.forEach((row, idx) => {
            const modifiedElement = validKeys.map((key, i) => {
                const value = row[arrayMap.indexOf(key)] ?? null;
                const valid = isValidRegex(key, value);
                return { name: key, value, valid, id: (currentPageForBulk - 1) * pageSizeForBulk + (idx + 1) };
            });
            dataToSet.push(modifiedElement);
        });
        return dataToSet;
    }

    const handleBulkPageButton = num => {
        setBulkUserDataCopy(
            bulkUserData.slice((num - 1) * pageSizeForBulk, num * pageSizeForBulk)
        );
        setCurrentPageForBulk(num);
    };

    const handleChangePageSize = size => {
        setBulkUserDataCopy(bulkUserData.slice(0, size));
        setPageSizeChangeForBulk(size);
        setNumPagesForBulk(Math?.ceil(bulkUserData?.length / size));
        setCurrentPageForBulk(1);
    };

    const isValidRegex = (key, value) => {
        let valid = true;
        switch (key) {
            case 'Supervisor': break;
            case 'first_name': break;
            case 'last_name': break;
            case 'unique_id': break;
            case 'Supervised_By': break;
            case 'One Time Password':
                valid =
                    value !== null &&
                    value !== undefined &&
                    value?.trim() !== '' &&
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                        value
                    );
                break;
            case 'employee_id':
            case 'Designation':
            case 'OBS':
            case 'Dashboard_role':
                valid = value !== null && value !== undefined;
                break;
            case 'email': break;
            // valid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
            //     value
            // );
            default:
                valid = false; // Invalid key
        }
        return valid;
    };

    const postData = async data => {
        const res = await client.post('/users', data);
        return res;
    };

    const putUserData = async data => {
        const res = await client.put(`/users/${data?.id}`, data);
        if (res.success) {
            if (searchedText) {
                handleSearchUser();
                setSearchedText('');
            } else {
                fetchData(organisationId);
            }
            handleCloseSingleUserModal();
            successNotify('User Updated Successfully!');
        } else if (res?.error?.response?.data?.message.includes("Username already taken")) {
            ErrorNotify("Employee ID already taken");
        } else {
            ErrorNotify(res?.error?.response?.data?.message);
        }
    };

    const getUserDetails = bulkData => {
        function getNameAndValue(arr) {
            return arr.reduce((result, item) => {
                if (item.name && item.value) {
                    result[item.name] = item.value;
                }
                return result;
            }, {});
        }
        const userDetail = getNameAndValue(bulkData);

        return bulkDataApiFormat(userDetail, organisationId);
    };

    function bulkDataApiFormat(inputValue, organisationId) {
        const validOBS = (
            Array.isArray(inputValue.OBS)
                ? inputValue.OBS
                : String(inputValue.OBS || '')
                    .split(',')
                    .map(id => parseInt(id.trim()))
        ).filter(id => id !== -1 && !isNaN(id));

        return {
            confirmed: true,
            username: inputValue.employee_id,
            first_name: inputValue.first_name,
            last_name: inputValue.last_name,
            employee_id: inputValue.employee_id,
            unique_id: inputValue.unique_id,
            email: inputValue.email,
            role: 1,
            temp_password: true,
            password: 'Password@123',
            organisations: {
                connect: [
                    {
                        id: organisationId,
                    },
                ],
            },
            Supervised_By: inputValue?.Supervised_By,
            role_map: [
                {
                    departments: validOBS.map(obsId => ({ id: obsId })),
                    organistation_roles: { id: inputValue.Designation },
                    user_role: { id: inputValue.Dashboard_role },
                },
            ],
        };
    }

    function mapValues(mapTo, mapfrom) {
        let result = {};

        for (let key in mapTo) {
            if (Array.isArray(mapTo[key])) {
                result[key] = mapTo[key].map(index => mapfrom[index]);
            }
        }

        return result;
    }

    const handleUploadBulkData = async empty => {
        if (empty) {
            setUserFailedData([]);
            setBulkUserData([]);
            handleClose3();
            setOptionBulkUser({});
            setBulkKeysExist(false);
            selectedOption.current = {}
            return;
        }
        setValidated(false);
        setLoadingButton(true)
        const failedDataIndex = [];
        let supervisorId = {};
        let SupervisedBy = {};
        let ResponseIds = {}
        for (let i = 0; i < bulkUserData?.length; i++) {
            const additionalFields = [
                {
                    name: 'OBS',
                    value: optionBulkUser.departments,
                    valid: true,
                    id: 1,
                },
                {
                    name: 'Designation',
                    value: optionBulkUser.organistation_roles,
                    valid: true,
                    id: 1,
                },
                {
                    name: 'Dashboard_role',
                    value: optionBulkUser.user_role,
                    valid: true,
                    id: 1,
                },
            ];
            const updatedUserData = [...bulkUserData[i], ...additionalFields];
            if (updatedUserData?.[3]?.name === 'Supervised_By') {
                if (supervisorId?.[updatedUserData?.[3]?.value]) {
                    const SupId = supervisorId?.[updatedUserData?.[3]?.value];
                    SupervisedBy = {
                        ...SupervisedBy,
                        [SupId]: [...SupervisedBy[SupId], i],
                    };
                    updatedUserData[3] = {
                        ...updatedUserData[3],
                        value: supervisorId?.[updatedUserData?.[3]?.value],
                    };
                } else {
                    if (updatedUserData?.[3]?.value != undefined) {
                        const res = await client.get(
                            `/users?_q=${updatedUserData?.[3]?.value}`
                        );
                        if (res.success) {
                            const email = updatedUserData?.[3]?.value + '';
                            const userId = res?.data?.[0]?.id;
                            supervisorId = {
                                ...supervisorId,
                                [email]: userId,
                            };
                            updatedUserData[3] = {
                                ...updatedUserData[3],
                                value: userId,
                            };
                            SupervisedBy = { ...SupervisedBy, [userId]: [i] };
                        }
                    }
                }
            }

            const payload = getUserDetails(updatedUserData);
            const res = await postData(payload);
            ResponseIds = { ...ResponseIds, [i]: res?.data?.data?.id };
            failedDataIndex.push({
                idx: i,
                success: res?.success,
                msg: res?.error?.response?.data?.message,
            });

            setUserFailedData(failedDataIndex);
            setBulkUserData([...bulkUserData]);
        }
        const UpdateSupervisor = mapValues(SupervisedBy, ResponseIds);
        for (let key in UpdateSupervisor) {
            if (key != "undefined") {
                await client.put(`/users/${key}`, {
                    Supervisor_Of: UpdateSupervisor[key],
                });
            }
        }

        await fetchData(organisationId);
        setOptionBulkUser({});
        setBulkKeysExist(false);
        setLoadingButton(false)
        selectedOption.current = {}
        setForBulk(false);
    };

    const handleSubmit = async userData => {
        if (showUserEditModal) {
            const data = formatAsAPI(
                userData,
                organisationId,
                dashboardRole,
                true
            );
            putUserData(data);
        } else {
            const data = formatAsAPI(userData, organisationId, dashboardRole);
            const res = await postData(data);
            if (res.success) {
                successNotify('User Created Successfully');
                handleCloseSingleUserModal();
                await fetchData(organisationId);
            }else if(res?.error?.response?.data?.message.includes("errors occurred")){
                ErrorNotify("Employee ID already taken");
            } else {
                ErrorNotify(res?.error?.response?.data?.message);
            }
        }
    };

    const getOrganizationRole = async () => {
        const res = await client.get(
            `/permission-groups/getOrgRole/${organisationId}`
        );
        if (res.success) {
            const designation = (res?.data || []).filter(
                item => !item.Role_Name.startsWith('Sys.')
            ); // for excluding designation with name Sys.
            setDesignation(designation);
        }
    };

    const getDashboardRole = async () => {
        const dROle = await client.get('/user-roles');
        setDashboardRole(dROle?.data);
    };

    const getSuperVisor = async () => {
        const res = await client.get(
            `/users?page=1&pageSize=1000&sort=username:ASC&filters[organisations]=${organisationId}`
        );
        setSupervisor(res?.data);
    };

    const fetchData = async id => {
        if (!id) return;
        setLoading(true);
        const response = await client.get(`/users/count?organisations=${id}`);
        const totalCount = response.data;
        setTotalUser(totalCount);
        setTotalUserCopy(totalCount);
        const pages = Math.ceil(totalCount / pageSize);
        setNumPages(pages);
        const startIndex =
            ((searchParams.get('page') || currentPage) - 1) * pageSize;
        const url = `/users?start=${startIndex}&limit=${pageSize}&sort=createdAt:DESC&filters[organisations]=${id}&populate=Supervised_By&populate=role_map&populate=role_map.departments&populate=role_map.organistation_roles&populate=role_map.user_role`;
        const res = await client.get(url);
        if (res.success) {
            setDashboardUserData(res?.data);
            setDashboardUserDataCopy(res?.data);
        }
        setLoading(false);
        setOptionBulkUser({});
        setBulkKeysExist(false);
        selectedOption.current = {}
    };

    const exportAllData = async id => {
        if (!id) return;
        const url = `/users-detail-export/${id}`;
        const res = await client.get(url);
        if (res.success) {
            csvDownload({
                data: res?.data,
                filename: `${organization.organisationName} ${moment().format('DD-MMM-YY')}`,
                delimiter: ',',
                headers: extractKeys(res.data),
            });
        }
    };

    function extractKeys(array) {
        if (array.length === 0) {
            return [];
        }
        const firstObject = array[0];
        return Object.keys(firstObject);
    }

    const tHeader = (userHeader = false, bulk = false) => {
        return (
            <tr>
                <th>Sl. No</th>
                <th>
                    First Name <sup className='sup_manatory text-danger'>*</sup>
                </th>
                <th>
                    Last Name <sup className='sup_manatory text-danger'>*</sup>
                </th>
                <th>
                    Employee ID{' '}
                    <sup className='sup_manatory text-danger'>*</sup>
                </th>
                {<th>Supervisor</th>}
                {(userHeader && !bulk) && (
                    <th>
                        Designation{' '}
                        <sup className='sup_manatory text-danger'>*</sup>
                    </th>
                )}
                {(userHeader && !bulk) && <th>OBS</th>}
                {(userHeader && !bulk) && (
                    <th>
                        User Name{' '}
                        <sup className='sup_manatory text-danger'>*</sup>
                    </th>
                )}
                <th>
                    Email ID <sup className='sup_manatory text-danger'>*</sup>
                </th>
                {(!userHeader && !bulk) && <th>One Time Password</th>}
                {(userHeader && !bulk) && <th> Role</th>}
                {(!userHeader && !bulk) && (
                    <th>
                        Unique ID{' '}
                        <sup className='sup_manatory text-danger'>*</sup>
                    </th>
                )}
                {bulk && <th>Unique Id <sup className='sup_manatory text-danger'>*</sup></th>}
                <th>Status</th>
                {(userHeader && !bulk) && <th>Edit</th>}
            </tr>
        );
    };

    const tableData = data => {
        return data.map((el, id) => {
            return (
                <tr key={id}>
                    <td>{id + 1 + (currentPageForBulk - 1) * pageSizeForBulk}</td>
                    {el.map((value, idx) => {
                        return (
                            <td
                                key={idx}
                                className={`text-start ${value?.valid
                                    ? ''
                                    : 'bg-soft-danger text-danger'
                                    }`}>
                                {value.value}
                            </td>
                        );
                    })}
                    <td>
                        {userFailedData?.length === 0 ? (
                            ''
                        ) : userFailedData[id]?.success ? (
                            <span style={{ color: 'green', cursor: 'pointer' }}>
                                {' '}
                                {toolTipHOC('Added', 'Added', true, 'top')}
                            </span>
                        ) : (
                            <span style={{ color: 'red', cursor: 'pointer' }}>
                                {toolTipHOC(
                                    'Not Added',
                                    (userFailedData[id]?.msg ==
                                        'username is a required field'
                                        ? 'Employee Id is a required field'
                                        : userFailedData[id]?.msg == 'This attribute must be unique' ? 'Employee Id and unique Id must be unique'
                                            : userFailedData[id]?.msg?.includes("errors") ? 'Please check All the Field'
                                                : userFailedData[id]?.msg?.length > 30 ? 'Please check All the Field' : userFailedData[id]?.msg
                                    ),
                                    true,
                                    'top'
                                )}
                            </span>
                        )}
                    </td>
                </tr>
            );
        });
    };
    const handleGetDataToCreateUser = () => {
        getOrganizationRole();
        getDashboardRole();
        getSuperVisor();
    };

    const handleSearchUser = async () => {
        const res = await client.get(
            `/users?page=1&pageSize=1000&sort=username:ASC&filters[organisations]=${organisationId}&_q=${searchedText}&populate=Supervised_By&populate=role_map&populate=role_map.departments&populate=role_map.organistation_roles&populate=role_map.user_role&filters[role_map][departments][Is_Active][$eq]=true`
        );
        if (res?.success) {
            setDashboardUserData(res?.data);
            setTotalUser(res?.data?.length);
        }
    };

    const hasPermission = (resource, type, subPermission = null) => {
        let toReturn = false;

        if (type == 'exists') {
            switch (resource) {
                case 'Agent':
                    if (
                        ['Agent', 'QA', 'Manager', 'Admin'].includes(
                            userInfo.role
                        )
                    ) {
                        return true;
                    }
                case 'QA':
                    if (['QA', 'Manager', 'Admin'].includes(userInfo.role)) {
                        return true;
                    }
                case 'Manager':
                    if (['Manager', 'Admin'].includes(userInfo.role)) {
                        return true;
                    }
                case 'Admin':
                    if (['Admin'].includes(userInfo.role)) {
                        return true;
                    }
            }
        } else {
            (permissions || []).map(permission => {
                if (permission.resource == resource && permission[type]) {
                    toReturn = true;
                }
            });
        }

        return toReturn;
    };

    const getObs = async(value)=>{
    const res = await client.get(`/organisations/obs/${value}`);
    setObsForOrganization(res?.data);
    }

    return (
        <>
            <div className='page-wrapper no_overflow_x'>
                <div className='content pb-0'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-12 sidebar_right_nav sidebar_right_style'>
                            <div className='sidebar-right'>
                                <p>Organization Setup</p>
                                <ul className='page_nav'>
                                    <DIYSideNav tab='user-management' />
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-9 col-md-8 col-sm-12 content_wrap_main content_wrap_style'>
                            <div className='content_wrap_comman'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='breadcrumb_box'>
                                            <Breadcrumb>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    href='/'>
                                                    <img
                                                        src={imagePath(
                                                            '/images/home_icon_pagig.svg'
                                                        )}
                                                    />
                                                </Breadcrumb.Item>
                                                <Breadcrumb.Item
                                                    as='li'
                                                    active
                                                    aria-current='page'>
                                                    Setup / User Onboarding
                                                </Breadcrumb.Item>
                                            </Breadcrumb>
                                        </div>
                                    </div>
                                </div>
                                <div className='two_column_title'>
                                    <h3 className='page_title'>
                                        User Management
                                    </h3>
                                    <div>
                                   
                                        <button
                                            className='btn bordered_btn'
                                            onClick={() => {
                                                handleShow4();
                                                handleGetDataToCreateUser();
                                            }}>
                                            Add User
                                        </button>
                                        <button
                                            className='btn comman_btn ms-3'
                                            onClick={() => {
                                                handleShow3();
                                                handleGetDataToCreateUser();
                                                setForBulk(true);
                                            }}>
                                            Import User
                                        </button>
                                        {hasPermission('Access Management', 'create') ?
                                            <button
                                                onClick={() => { navigate(`/o/${orgStrId}/setup/role-permission`) }}
                                                className='btn comman_btn ms-3'>
                                                Role Permissions
                                            </button> : ("")}
                                    </div>
                                </div>

                                <div
                                    style={{
                                        marginTop: '15px',
                                        marginBottom: '-10px',
                                    }}>
                                    <div className='row'>
                                        <div className='col-lg-3'>
                                        <div className='form_style_comman'>
                                    {forOmindPersona && 
                                            <Select
                                                options={organizationOption.map(des => ({
                                                        value: des.id,
                                                        label: des?.attributes
                                                            ?.Organisation_Name,
                                                    })
                                                )}
                                                defaultValue={selectedOrganization}
                                                onChange={selectedOption => {
                                                    setOrganisationId(
                                                        selectedOption?.value
                                                    );
                                                    setSelectedOrganization({
                                                        label: selectedOption?.label,
                                                        value: selectedOption?.value
                                                    })
                                                    getObs(selectedOption?.value)
                                                }}
                                                value={selectedOrganization}
                                                isSearchable
                                                placeholder='Search Organization'
                                            />
                                        
                                   }
                                    </div>
                                        </div>
                                        <div className='col-lg-9 d-flex justify-content-end'>
                                        <div className='table_exp_area d-flex'>
                                        <button
                                            type='button'
                                            className='btn exp_btn ms-3'
                                            onClick={() =>
                                                exportAllData(organisationId)
                                            }>
                                            <span>
                                                <img
                                                    src={
                                                        process.env.PUBLIC_URL +
                                                        '/images/excel_icon2.svg'
                                                    }
                                                />
                                            </span>{' '}
                                            Export Users
                                        </button>
                                    </div>
                                    <div className='useronboard_search ms-3'>
                                        <input
                                            className='form-control'
                                            value={searchedText}
                                            onKeyDown={e => {
                                                if (e.key == 'Enter') {
                                                    handleSearchUser();
                                                }
                                            }}
                                            onChange={e =>
                                                setSearchedText(e.target.value)
                                            }
                                            type='text'
                                            placeholder='Search By Email / User Name / First Name / Last Name'
                                        />
                                        {searchedText && (
                                            <button
                                                className='btn diy_emp_cancel_btn'
                                                onClick={() => {
                                                    setDashboardUserData(
                                                        dashboardUserDataCopy
                                                    );
                                                    setTotalUser(totalUserCopy);
                                                    setSearchedText('');
                                                }}>
                                                X
                                            </button>
                                        )}
                                        <button
                                            className='diy_emp_search_btn'
                                            onClick={handleSearchUser}>
                                            <i
                                                className='fa fa-search'
                                                aria-hidden='true'></i>
                                        </button>
                                    </div>
                                        </div>
                                    </div>
                                    
                                    
                                </div>

                                <div className='table_style_comman agent_tbl_scorewise scrollbar_style diy_user_tbl mt-4 mb-3'>
                                    <div className='table-responsive'>
                                        <table className='table'>
                                            <thead>{tHeader(true)}</thead>
                                            <tbody>
                                                {loading
                                                    ? loadingData(12)
                                                    : dashboardUserData?.map(
                                                        (el, i) => (
                                                            <tr key={i}>
                                                                <td>
                                                                    {(currentPage -
                                                                        1) *
                                                                        pageSize +
                                                                        (i +
                                                                            1)}
                                                                </td>
                                                                <td className='text_ellipses'>
                                                                    {toolTipHOC(
                                                                        el?.first_name,
                                                                        el?.first_name,
                                                                        true,
                                                                        'top'
                                                                    )}
                                                                </td>
                                                                <td className='text_ellipses'>
                                                                    {toolTipHOC(
                                                                        el?.last_name,
                                                                        el?.last_name,
                                                                        true,
                                                                        'top'
                                                                    )}
                                                                </td>
                                                                <td className='text_ellipses'>
                                                                    {toolTipHOC(
                                                                        el?.employee_id ||
                                                                        el?.username,
                                                                        el?.employee_id ||
                                                                        el?.username,
                                                                        true,
                                                                        'top'
                                                                    )}
                                                                </td>
                                                                <td className='text_ellipses'>
                                                                    {toolTipHOC(
                                                                        (el
                                                                            ?.Supervised_By
                                                                            ?.first_name ||
                                                                            '') +
                                                                        ' ' +
                                                                        (el
                                                                            ?.Supervised_By
                                                                            ?.last_name ||
                                                                            ''),
                                                                        (el
                                                                            ?.Supervised_By
                                                                            ?.first_name ||
                                                                            '') +
                                                                        ' ' +
                                                                        (el
                                                                            ?.Supervised_By
                                                                            ?.last_name ||
                                                                            ''),
                                                                        true,
                                                                        'top'
                                                                    )}
                                                                </td>
                                                                <td className='text_ellipses'>
                                                                    {toolTipHOC(
                                                                        el
                                                                            ?.role_map?.[0]
                                                                            ?.organistation_roles?.[0]
                                                                            ?.Role_Name,
                                                                        el
                                                                            ?.role_map?.[0]
                                                                            ?.organistation_roles?.[0]
                                                                            ?.Role_Name,
                                                                        true,
                                                                        'top'
                                                                    )}
                                                                </td>
                                                                <td className='text_ellipses'>
                                                                    {toolTipHOC(
                                                                        el?.role_map?.[0]?.departments
                                                                            .map(
                                                                                department =>
                                                                                    department.Department_Name
                                                                            )
                                                                            .join(
                                                                                ', '
                                                                            ),
                                                                        el?.role_map?.[0]?.departments
                                                                            .map(
                                                                                department =>
                                                                                    department.Department_Name
                                                                            )
                                                                            .join(
                                                                                ', '
                                                                            ),
                                                                        true,
                                                                        'top'
                                                                    )}
                                                                </td>
                                                                <td className='text_ellipses'>
                                                                    {toolTipHOC(
                                                                        el?.username,
                                                                        el?.username,
                                                                        true,
                                                                        'top'
                                                                    )}
                                                                </td>
                                                                <td className='text_ellipses'>
                                                                    {toolTipHOC(
                                                                        el?.email,
                                                                        el?.email,
                                                                        true,
                                                                        'top'
                                                                    )}
                                                                </td>
                                                                <td className='text_ellipses'>
                                                                    {
                                                                        el
                                                                            ?.role_map?.[0]
                                                                            ?.user_role
                                                                            ?.role
                                                                    }
                                                                </td>
                                                                <td className='text_ellipses'>
                                                                    {el?.blocked ? (
                                                                        <span
                                                                            style={{
                                                                                color: 'red',
                                                                            }}>
                                                                            In-Active
                                                                        </span>
                                                                    ) : (
                                                                        <span
                                                                            style={{
                                                                                color: 'green',
                                                                            }}>
                                                                            Active
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                    }}
                                                                    onClick={() => {
                                                                        addUserEditDataFunc(
                                                                            el
                                                                        );
                                                                        handleShow4();
                                                                        handleGetDataToCreateUser();
                                                                        setShowUserEditModal(
                                                                            true
                                                                        );
                                                                    }}>
                                                                    <img
                                                                        alt=''
                                                                        src={imagePath(
                                                                            '/images/edit_pencil.svg'
                                                                        )}></img>
                                                                    {/* <CiEdit /> */}
                                                                </td>
                                                            </tr>
                                                        )
                                                    )}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                {(dashboardUserData?.length >= 10 ||
                                    currentPage !== 1) && (
                                        <PaginationBar
                                            nPages={numPages}
                                            currentPage={currentPage}
                                            base={`/o/${orgStrId}/setup/user-management`}
                                            pageSize={pageSize}
                                            onPageSizeChange={setPageSizeChange}
                                            totalUser={totalUser}
                                            paginationForDIY={true}
                                        />
                                    )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*  */}
            <Modal
                show={show4}
                onHide={() => {
                    handleCloseSingleUserModal();
                }}
                backdrop='static'
                keyboard={false}
                className='obs_single_user_modal'>
                <Modal.Header closeButton>
                    <h4 className='mt-4 text-primary'>
                        {showUserEditModal ? 'Edit User' : 'Add User'}
                    </h4>
                </Modal.Header>
                <Modal.Body>
                    <UserForm
                        handleSubmit={handleSubmit}
                        dashboardRole={dashboardRole}
                        designation={designation}
                        supervisor={supervisor}
                        userDataForEdit={userData}
                        handleCloseSingleUserModal={handleCloseSingleUserModal}
                        showUserEditModal={showUserEditModal}
                        obsForOrganization={obsForOrganization}
                    />
                </Modal.Body>
            </Modal>

            {/* bulk user upload modal */}
            <Modal
                show={show3}
                onHide={() => {
                    handleClose3();
                    setValidated(false);
                    setOptionBulkUser({});
                    setBulkKeysExist(false);
                    selectedOption.current = {};
                    setForBulk(false);
                    setCurrentPageForBulk(1);
                    setPageSizeChangeForBulk(10);
                    setTotalUserForBulk(10);
                    setNumPagesForBulk(1);
                }}
                backdrop='static'
                keyboard={false}
                className='obs_single_user_modal_bulk'>
                <Modal.Header closeButton>
                    <h4 className='mt-4 text-primary'>Import Users</h4>
                </Modal.Header>
                <Modal.Body>
                    {bulkUserData?.length > 0 ? (
                        <div className='audit_list_view'>
                            <div >
                                <BulkUploadOptions
                                    handleSubmit={handleSubmit}
                                    dashboardRole={dashboardRole}
                                    designation={designation}
                                    userDataForEdit={userData}
                                    handleCloseSingleUserModal={
                                        handleCloseSingleUserModal
                                    }
                                    showUserEditModal={showUserEditModal}
                                    handleChangeOption={handleChangeSingleUser}
                                    optionBulkUser={optionBulkUser}
                                    validated={validated}
                                    bulkUserData={bulkUserData}
                                    obsForOrganization = {obsForOrganization}
                                />
                            </div>
                            <div className='tab_view'>
                                <Tab.Container defaultActiveKey='first'>
                                    <Nav
                                        variant='pills'
                                        data-toggle='slider-tab'
                                        role='tablist'>
                                        <Nav.Item role='presentation'>
                                            <Nav.Link
                                                eventKey='first'
                                                variant='  d-flex align-items-center'
                                                data-bs-toggle='tab'
                                                data-bs-target='#pills-list_audit'
                                                type='button'
                                                role='tab'
                                                aria-controls='home'
                                                aria-selected='true'>
                                                All
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                    <Tab.Content className='mt-4'>
                                        <Tab.Pane
                                            eventKey='first'
                                            variant=' fade show active'
                                            id='pills-list_audit'
                                            role='tabpanel'
                                            aria-labelledby='pills-list_audit-tab1'>
                                            <div className='table_style_comman obs_user_list scrollbar_style'>
                                                <div className='table-responsive'>
                                                    <table className='table text-left'>
                                                        <thead>
                                                            {tHeader(
                                                                false,
                                                                true
                                                            )}
                                                        </thead>
                                                        <tbody>
                                                            {tableData(
                                                                bulkUserDataCopy
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Tab.Container>
                            </div>
                            {
                                <PaginationBar
                                    nPages={numPagesForBulk}
                                    currentPage={currentPageForBulk}
                                    base={`/o/${orgStrId}/setup/user-management`}
                                    pageSize={pageSizeForBulk}
                                    onPageSizeChange={handleChangePageSize}
                                    totalUser={totalUserForBulk}
                                    paginationForDIY={true}
                                    forBulk={forBulk}
                                    handleBulkPageButton={handleBulkPageButton}
                                />
                            }
                        </div>
                    ) : (
                        <>
                            <div >
                                <BulkUploadOptions
                                    handleSubmit={handleSubmit}
                                    dashboardRole={dashboardRole}
                                    designation={designation}
                                    userDataForEdit={userData}
                                    handleCloseSingleUserModal={
                                        handleCloseSingleUserModal
                                    }
                                    showUserEditModal={showUserEditModal}
                                    handleChangeOption={handleChangeSingleUser}
                                    optionBulkUser={optionBulkUser}
                                    validated={validated}
                                    bulkUserData={bulkUserData}
                                    obsForOrganization = {obsForOrganization}
                                />
                            </div>

                            <div className='drag_file_upload_area text-center mt-2'>
                                <div className='drag_file_upload_inner'>
                                    <img
                                        src={imagePath(
                                            '/images/multi_upload_icon.svg'
                                        )}
                                        alt=''
                                    />
                                    <h5 className='h5 mt-3'>
                                        Upload CSV
                                    </h5>
                                    <p>
                                        CSV (max 5MB) or XLSX/XLS (max 5MB) |
                                        Upto 1mn rows
                                    </p>
                                    <div
                                        className='mt-4 d-flex justify-content-center'>
                                        <div className='file_upload_custom'>
                                            <button style={{ color: bulkKeysExist ? "#072b15" : "gray" }} onClick={() => setValidated(true)} className={!bulkKeysExist ? "btn me-3 pointer_cur" : "btn me-3"}>
                                                Select File
                                            </button>

                                            <input
                                                type='file'
                                                name='myfile'
                                                accept='.xlsx, .csv'
                                                onChange={handleFileUpload}
                                                style={{
                                                    pointerEvents: bulkKeysExist
                                                        ? 'auto'
                                                        : 'none',
                                                    border: '1px solid black'
                                                }}
                                            />
                                        </div>
                                        <button
                                            className='btn bordered_btn pointer_cu'
                                            onClick={() => {
                                                // const sampleFileDownload = () => {
                                                const fileUrl =
                                                    '/userDataSample.xlsx';
                                                const anchor =
                                                    document.createElement('a');
                                                anchor.href = fileUrl;
                                                anchor.download =
                                                    'userDataSample.xlsx';
                                                anchor.click();
                                                //   };
                                            }}>
                                            Download Sample
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                    <div className='btn_right mt-4'>
                        <Button
                            disabled={
                                isButtonDisabled ||
                                (bulkUserData?.length > 0 ? false : true) ||
                                loadingButton
                            }
                            onClick={() => {
                                handleUploadBulkData(
                                    userFailedData?.length > 0 ? 'empty' : ''
                                );
                            }}
                            className='btn comman_btn'>
                            {loadingButton ? (
                                <>
                                    <Spinner
                                        className='spinner-button'
                                        style={{ color: 'blue' }}></Spinner>
                                    <span> Importing</span>
                                </>
                            ) : (
                                <>
                                    {userFailedData?.length > 0
                                        ? 'Close'
                                        : 'Import Users'}
                                </>
                            )}
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DiyLandingScreen;

function formatAsAPI(
    inputValue,
    organisationId,
    dashboardRole,
    update = false
) {
    const apiData = {
        id: inputValue?.id,
        blocked: inputValue?.blocked,
        confirmed: true,
        username: inputValue?.employee_id,
        unique_id: inputValue?.employee_id,
        first_name: inputValue.first_name,
        last_name: inputValue.last_name,
        employee_id: inputValue.employee_id,
        email: inputValue.email,
        role: 1,
        organisations: {
            connect: [
                {
                    id: organisationId,
                },
            ],
        },
        role_map: [
            {
                departments: inputValue?.departments
                    ? reFactorDepartment(
                        (inputValue?.departments + '')?.split(',')
                    )
                    : { id: null },
                organistation_roles: { id: inputValue.organistation_roles },
                user_role: { id: inputValue.user_role },
            },
        ],
    };

    if (inputValue.Supervised_By) {
        apiData.Supervised_By = inputValue.Supervised_By;
    }
    if (!update) {
        apiData.password = 'Password@123';
        apiData.temp_password = true;
    } else if (inputValue?.password) {
        apiData.password = inputValue.password;
        apiData.temp_password = true;
    }

    return apiData;
}

const reFactorDepartment = arr => {
    const positiveArr = arr.map(numStr => Math.abs(parseInt(numStr)));
    if (positiveArr.length > 0 && positiveArr[0] === 1) {
        positiveArr.shift();
    }
    return positiveArr;
};
